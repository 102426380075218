import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { PROJECTS, PROJECT_VALUE_DIVIDED } from "../keys";
import { format } from "date-fns";

const useProjectValueDividedYear = ({projectId}) => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }

  return useQuery({
    queryKey: [PROJECTS, PROJECT_VALUE_DIVIDED, tenant.id, projectId, startDate, endDate],
    queryFn: () => ApiService.getProjectValueDividedYear(projectId, since, until),
    select: (data) => data.data,
    placeholderData: {
      data: {
        total: 0,
        trend: 0,
        series: [],
      }
    },
    enabled,
  });
};

export default useProjectValueDividedYear;
