import { useQuery } from "@tanstack/react-query";
import ApiService from "../../services/ApiService";
import { format } from "date-fns";
import { useSelector } from "react-redux";

export const useLogoDetectionStats = (sponsorObjectId) => {
  const date = useSelector((state) => state.date);
  const { startDate, endDate } = date;
  const enabled = !!(startDate && endDate);
  
  let since;
  let until;
  if (enabled) {
    since = format(new Date(startDate), "yyyy-MM-dd");
    until = format(new Date(endDate), "yyyy-MM-dd");
  }
  
  return useQuery({
    queryKey: ["logo-detection-stats", startDate, endDate, sponsorObjectId],
    queryFn: () => ApiService.getLogoDetectionStats(since, until, sponsorObjectId),
    enabled: enabled,
    placeholderData: { data: [] }
  });
}
