import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useThemeContext } from '../../../contexts/ThemeContext';

const RECOGNITION_LEVELS = {
  '0': 'Kjenner ikke til som sponsor',
  '1': 'Kjenner til som sponsor'
};

const AGE_GROUP_LABELS = {
  '1': '15-24 år',
  '2': '25-34 år',
  '3': '35-44 år',
  '4': '45-54 år',
  '5': '55-64 år',
  '6': '65+ år'
};

const AGE_GROUP_COLORS = {
  '1': '#2F7EC7',  // Blue
  '2': '#14B8A6',  // Teal
  '3': '#F76707',  // Orange
  '4': '#8B5CF6',  // Purple
  '5': '#059669',  // Green
  '6': '#DC2626'   // Red
};

const BrandRecognitionChart = ({ data = [], compareMode, loading, filterName, baseAudienceName, selectedFilters }) => {
  const { theme } = useThemeContext();

  const chartOptions = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) {
      return null;
    }

    // Check if we should show comparison data
    // Only show comparison if it's from a sponsor filter
    const showComparison = compareMode && selectedFilters?.[0]?.type === 'sponsor';

    // Process the data
    const groupedData = {};
    data.forEach(item => {
      const ageGroup = item.age_group;
      const recognitionLevel = item.recognition_level === "1" ? 'Kjenner til som sponsor' : 'Kjenner ikke til som sponsor';
      
      if (!groupedData[recognitionLevel]) {
        groupedData[recognitionLevel] = {};
      }
      if (!groupedData[recognitionLevel][ageGroup]) {
        groupedData[recognitionLevel][ageGroup] = {
          finalPercentage: 0,
          finalComparisonPercentage: 0,
          count: 0,
          comparisonCount: 0
        };
      }

      // Add to the counts and percentages
      groupedData[recognitionLevel][ageGroup].count += parseInt(item.count || 0);
      if (showComparison && item.comparison) {
        groupedData[recognitionLevel][ageGroup].comparisonCount += parseInt(item.comparison.count || 0);
      }
    });

    // Calculate percentages based on total counts for each age group
    Object.keys(groupedData).forEach(level => {
      Object.keys(groupedData[level]).forEach(ageGroup => {
        // Get total count for this age group (sum of both recognition levels)
        const totalCount = Object.keys(groupedData).reduce((sum, recLevel) => {
          return sum + (groupedData[recLevel][ageGroup]?.count || 0);
        }, 0);
        
        // Get total comparison count for this age group if we're showing comparison
        const totalCompCount = showComparison ? Object.keys(groupedData).reduce((sum, recLevel) => {
          return sum + (groupedData[recLevel][ageGroup]?.comparisonCount || 0);
        }, 0) : 0;

        if (totalCount > 0) {
          groupedData[level][ageGroup].finalPercentage = (groupedData[level][ageGroup].count / totalCount) * 100;
        }
        if (showComparison && totalCompCount > 0) {
          groupedData[level][ageGroup].finalComparisonPercentage = (groupedData[level][ageGroup].comparisonCount / totalCompCount) * 100;
        }
      });
    });

    // Create series for each age group
    const ageSeries = Object.keys(AGE_GROUP_LABELS).map((ageGroup) => {
      const seriesData = [
        {
          y: groupedData['Kjenner til som sponsor']?.[ageGroup]?.finalPercentage ?? 0,
          ageGroup: ageGroup
        },
        showComparison ? {
          y: groupedData['Kjenner til som sponsor']?.[ageGroup]?.finalComparisonPercentage ?? 0,
          ageGroup: ageGroup
        } : null,
        {
          y: groupedData['Kjenner ikke til som sponsor']?.[ageGroup]?.finalPercentage ?? 0,
          ageGroup: ageGroup
        },
        showComparison ? {
          y: groupedData['Kjenner ikke til som sponsor']?.[ageGroup]?.finalComparisonPercentage ?? 0,
          ageGroup: ageGroup
        } : null
      ].filter(x => x !== null);

      return {
        name: AGE_GROUP_LABELS[ageGroup],
        color: AGE_GROUP_COLORS[ageGroup],
        data: seriesData
      };
    });

    // Update chart options
    const options = {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          color: "var(--text-primary)"
        },
        marginTop: 50,
        events: {
          render: function() {
            const chart = this;
            if (chart.sectionLabels) {
              chart.sectionLabels.forEach(t => t.destroy());
            }
            chart.sectionLabels = [];

            // Add section labels - always just two labels
            const leftX = chart.plotLeft + chart.plotWidth * 0.25;
            const rightX = chart.plotLeft + chart.plotWidth * 0.75;

            // Add "Kjenner til som sponsor" label on left
            chart.sectionLabels.push(
              chart.renderer.text('Kjenner til som sponsor', leftX, chart.plotTop - 20)
                .attr({
                  align: 'center',
                  zIndex: 5
                })
                .css({
                  color: "var(--text-primary)",
                  fontSize: '12px',
                  fontWeight: 'bold'
                })
                .add()
            );

            // Add "Kjenner ikke til som sponsor" label on right
            chart.sectionLabels.push(
              chart.renderer.text('Kjenner ikke til som sponsor', rightX, chart.plotTop - 20)
                .attr({
                  align: 'center',
                  zIndex: 5
                })
                .css({
                  color: "var(--text-primary)",
                  fontSize: '12px',
                  fontWeight: 'bold'
                })
                .add()
            );
          }
        }
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: showComparison ? 
          [baseAudienceName, filterName || 'Comparison', baseAudienceName, filterName || 'Comparison'] :
          [baseAudienceName, baseAudienceName],
        title: {
          style: {
            color: "var(--text-primary)"
          }
        },
        labels: {
          style: {
            color: "var(--text-primary)",
            fontWeight: 'bold',
            fontSize: '11px'
          }
        },
        lineColor: "var(--text-primary)",
        tickColor: "var(--text-primary)"
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true,
            formatter: function() {
              return this.point.y > 0 ? AGE_GROUP_LABELS[this.point.ageGroup] : '';
            },
            style: {
              color: "var(--text-primary)",
              textOutline: 'none',
              fontSize: '11px'
            }
          }
        },
        series: {
          pointPadding: 0.1,
          groupPadding: 0.2
        }
      },
      yAxis: {
        title: {
          text: 'Percentage',
          style: {
            color: "var(--text-primary)"
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: "var(--text-primary)"
          }
        },
        gridLineColor: "var(--text-secondary)",
        lineColor: "var(--text-primary)",
        tickColor: "var(--text-primary)"
      },
      tooltip: {
        shared: true,
        backgroundColor: theme === 'dark' ? '#1F2937' : '#FFFFFF',
        style: {
          color: "var(--text-primary)"
        },
        formatter: function() {
          let tooltip = `<b>${this.x}</b><br/>`;
          this.points.forEach(point => {
            tooltip += `${AGE_GROUP_LABELS[point.point.ageGroup]}: <b>${point.y.toFixed(1)}%</b><br/>`;
          });
          return tooltip;
        }
      },
      series: ageSeries
    };

    return options;
  }, [data, compareMode, filterName, baseAudienceName, theme, selectedFilters]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!chartOptions) {
    return <div>No data available</div>;
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};

export default BrandRecognitionChart;
