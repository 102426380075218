import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { IMAGE_DETECTION_EXAMPLE, PARTNERS } from "../keys";

const usePartnerImageDetectionExample = (partnerId, sponsorObjectId) => {
  const tenant = useSelector((state) => state.auth.tenant);
  return useQuery({
    queryKey: [PARTNERS, IMAGE_DETECTION_EXAMPLE, tenant.id, partnerId, sponsorObjectId],
    queryFn: () => ApiService.getParnterImageDetectionExample(partnerId, sponsorObjectId),
    select: (data) => data.data,
    placeholderData: { data: [] },
  });
};
export default usePartnerImageDetectionExample;
