import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Select from 'react-select';
import ApiService from '../../services/ApiService';
import { FILTER_CATEGORIES } from './config/filterConfigs';
import { useThemeContext } from '../../contexts/ThemeContext';

const useFilterOptions = () => {
  const [options, setOptions] = useState({
    interests: [],
    sponsors: [],
    brands: [],
    athletes: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await ApiService.getAudienceInsightsFilterOptions();
        const { interests = [], sponsors = [], brands = [], athletes = [] } = response.data;
        
        setOptions({
          interests: interests.map(item => ({
            value: item.code,
            label: item.name,
            rowTitle: item.rowTitle || item.name,
            type: 'interest'
          })),
          sponsors: sponsors.map(item => ({
            value: item.code,
            label: item.name,
            rowTitle: item.rowTitle || item.name,
            type: 'sponsor'
          })),
          brands: brands.map(item => ({
            value: item.code,
            label: item.name,
            rowTitle: item.rowTitle || item.name,
            type: 'brand'
          })),
          athletes: athletes.map(item => ({
            value: item.code,
            label: item.name,
            rowTitle: item.rowTitle || item.name,
            type: 'athlete'
          }))
        });
      } catch (err) {
        console.error('Error loading filter options:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFilterOptions();
  }, []);

  return { options, loading, error };
};

const FilterPanel = ({
  selectedFilters = [],
  onChange,
  compareMode,
  onCompareModeChange,
  baseAudience,
  onBaseAudienceChange,
  tenantBrand
}) => {
  const { isDarkMode } = useThemeContext();
  const { options, loading, error } = useFilterOptions();

  // Initialize selected comparisons from props
  const [selectedComparisons, setSelectedComparisons] = useState({
    interest: selectedFilters.find(f => f.type === 'interest') || null,
    sponsor: selectedFilters.find(f => f.type === 'sponsor') || null,
    brand: selectedFilters.find(f => f.type === 'brand') || null,
    athlete: selectedFilters.find(f => f.type === 'athlete') || null
  });

  // Update selected comparisons when props change
  useEffect(() => {
    setSelectedComparisons({
      interest: selectedFilters.find(f => f.type === 'interest') || null,
      sponsor: selectedFilters.find(f => f.type === 'sponsor') || null,
      brand: selectedFilters.find(f => f.type === 'brand') || null,
      athlete: selectedFilters.find(f => f.type === 'athlete') || null
    });
  }, [selectedFilters]);

  const handleComparisonChange = (type, selected) => {
    // Only allow up to two selections total
    const currentSelections = Object.values(selectedComparisons).filter(Boolean).length;
    const isDeselecting = !selected;
    
    if (currentSelections >= 2 && !isDeselecting && selectedComparisons[type] === null) {
      return; // Don't allow more than 2 selections
    }

    const newComparisons = {
      ...selectedComparisons,
      [type]: selected
    };
    setSelectedComparisons(newComparisons);

    // Notify parent component of all current selections
    const allSelections = Object.values(newComparisons).filter(Boolean);
    onChange(allSelections);
  };

  const handleBaseAudienceChange = (value) => {
    onBaseAudienceChange(value);
    // If switching to 'everyone', we need a comparison filter to show brand recognition/associations
    if (value === 'everyone' && !compareMode && selectedFilters.length === 0) {
      onCompareModeChange(true);
    }
  };

  if (loading) {
    return (
      <Card className="mb-4">
        <CardBody>
          <div>Loading filter options...</div>
        </CardBody>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="mb-4">
        <CardBody>
          <div>Error loading filter options. Please try again later.</div>
        </CardBody>
      </Card>
    );
  }

  return (
    <div className="filter-panel">
      <Card className="mb-4">
        <CardBody>
          <div className="base-audience-selector">
            <label>Base Audience:</label>
            <select 
              value={baseAudience} 
              onChange={(e) => handleBaseAudienceChange(e.target.value)}
              className={isDarkMode ? 'dark' : ''}
            >
              <option value={tenantBrand.code}>{tenantBrand.name}</option>
              <option value="everyone">Everyone</option>
            </select>
          </div>
      

          <div className="filter-categories">
            {Object.entries(FILTER_CATEGORIES).map(([key, category]) => {
              if (!category.availableWhen || category.availableWhen(baseAudience)) {
                return (
                  <Card key={key} className={`filter-category ${isDarkMode ? 'dark' : ''}`}>
                    <CardBody>
                      <h3 className="category-title">{category.title}</h3>
                      <p className="category-description">{category.description}</p>
                      <div className="filter-options">
                        {category.type === 'interest' && (
                          <Select
                            className="react-select__container"
                            classNamePrefix="react-select"
                            value={selectedComparisons.interest}
                            onChange={(selected) => handleComparisonChange('interest', selected)}
                            options={options.interests}
                            isClearable
                            placeholder="Select an interest..."
                          />
                        )}
                        {category.type === 'sponsor' && (
                          <Select
                            className="react-select__container"
                            classNamePrefix="react-select"
                            value={selectedComparisons.sponsor}
                            onChange={(selected) => handleComparisonChange('sponsor', selected)}
                            options={options.sponsors}
                            isClearable
                            placeholder="Select a sponsor..."
                          />
                        )}
                        {category.type === 'brand' && (
                          <Select
                            className="react-select__container"
                            classNamePrefix="react-select"
                            value={selectedComparisons.brand}
                            onChange={(selected) => handleComparisonChange('brand', selected)}
                            options={options.brands}
                            isClearable
                            placeholder="Select a brand..."
                          />
                        )}
                        {category.type === 'athlete' && (
                          <Select
                            className="react-select__container"
                            classNamePrefix="react-select"
                            value={selectedComparisons.athlete}
                            onChange={(selected) => handleComparisonChange('athlete', selected)}
                            options={options.athletes}
                            isClearable
                            placeholder="Select an athlete..."
                          />
                        )}
                      </div>
                    </CardBody>
                  </Card>
                );
              }
              return null;
            })}
          </div>
      </CardBody>
      </Card>
    </div>
  );
};

export default FilterPanel;
