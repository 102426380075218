import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { FaFacebook, FaInstagram, FaPlayCircle } from "react-icons/fa";
import useSocialMediaPost from '../query/hooks/useSocialMediaPost';

const SocialMediaPostWidget = ({post, aspectRatio = '16 / 9', isLoadingPost = false}) => {
  const internalId = post?.facebook_post_id || post?.instagram_post_id || post?.id;
  const {data} = useSocialMediaPost({...post, id: internalId}, post?.s3_url === null);
  if (!post) {
    return null;
  }

  let Icon = FaFacebook;
  const media = post?.media?.toLowerCase?.() ?? 'facebook';
  if (media === 'instagram') {
    Icon = FaInstagram;
  }

  const renderPreview = () => {
    if (post.thumbnail_url) {
      return (
        <img
          src={post.thumbnail_url}
          className="post-image"
          style={{aspectRatio}}
          alt=""
        />
      );
    }

    if (post.media_type === 'VIDEO') {
      return (
        <video
          src={post.s3_url}
          className="post-image"
          style={{aspectRatio}}
          alt=""
        />
      )
    }

    return (
      <>
      <img
        src={post?.s3_url ?? data?.media_url}
        className="post-image"
        style={{aspectRatio}}
        alt=""
      />
      </>
    )
  }

  return (
    <a href={post.permalink} className="social-media-post" target="_blank" rel="noreferrer">
      <Card>
        <div className="post-media">
          <div className={`post-icon ${media}`}>
            <Icon />
          </div>
          {
            <>
              {post.media_type === 'VIDEO' && (
                <div className="post-video-badge">
                  <FaPlayCircle /> Video
                </div>
              )}
              {renderPreview()}
            </>
          }
        </div>
        <CardBody className="post-content">
          <div className="post-message">
            {post.message}
          </div>
        </CardBody>
      </Card>
    </a>
  );
};

export default SocialMediaPostWidget;