import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import ApiService from '../../services/ApiService';
import FilterPanel from './FilterPanel';
import { CHART_CONFIGS } from './config/chartConfigs';
import { useThemeContext } from '../../contexts/ThemeContext';

const STORAGE_KEY = 'audienceInsights';

const useAudienceData = (compareMode, selectedFilters, baseAudience, isInitialized) => {
  const [loadingStates, setLoadingStates] = useState({
    insights: true,
    demographics: true,
    geography: true,
    channels: true,
    brands: true,
    recognition: true,
    athleteRecognition: true
  });

  const [data, setData] = useState({
    insights: null,
    demographics: null,
    geography: { base: null, comparison: null },
    channels: null,
    brandAssociations: null,
    recognition: null,
    athleteRecognition: null
  });

  // Initialize visibleCharts with the initial state based on current props
  const [visibleCharts, setVisibleCharts] = useState(() => {
    const initialVisibleCharts = {};
    Object.entries(CHART_CONFIGS).forEach(([key, config]) => {
      initialVisibleCharts[key] = config.showConditions(baseAudience, selectedFilters);
    });
    return initialVisibleCharts;
  });
  const [error, setError] = useState(null);

  // Update visible charts whenever base audience or filters change
  useEffect(() => {
    if (!isInitialized) return;
    const newVisibleCharts = {};
    Object.entries(CHART_CONFIGS).forEach(([key, config]) => {
      newVisibleCharts[key] = config.showConditions(baseAudience, selectedFilters);
    });
    setVisibleCharts(newVisibleCharts);

    // Clear data for charts that are no longer visible
    setData(prev => {
      const newData = { ...prev };
      Object.keys(CHART_CONFIGS).forEach(key => {
        if (!newVisibleCharts[key]) {
          newData[key] = null;
        }
      });
      return newData;
    });
  }, [baseAudience, selectedFilters, isInitialized]);

  const getComparisonCode = () => {
    if (!compareMode || selectedFilters.length === 0) return null;
    return selectedFilters[0]?.value || null;
  };

  const fetchDemographics = async () => {
    if (!isInitialized) return;
    try {
      setLoadingStates(prev => ({ ...prev, demographics: true }));
      const comparisonCode = getComparisonCode();
      const response = await ApiService.getAudienceInsightsDemographics(comparisonCode, baseAudience);
      const demographicsData = response.data.data || response.data;
      
      // Demographics data already includes comparison data in the response
      setData(prev => ({
        ...prev,
        demographics: demographicsData
      }));
    } catch (err) {
      console.error('Error fetching demographics:', err);
      setError(err);
    } finally {
      setLoadingStates(prev => ({ ...prev, demographics: false }));
    }
  };

  const fetchBrandAssociations = async () => {
    if (!isInitialized) return;
    // Check visibility using the config directly to ensure we have the latest state
    const isVisible = CHART_CONFIGS.brandAssociations.showConditions(baseAudience, selectedFilters);
    if (!isVisible) {
      setData(prev => ({
        ...prev,
        brandAssociations: []
      }));
      return;
    }

    try {
      setLoadingStates(prev => ({ ...prev, brands: true }));
      const comparisonCode = getComparisonCode();
      const response = await ApiService.getAudienceInsightsBrandAssociations(null, comparisonCode, baseAudience);
      const brandData = response.data.data || response.data;
      
      // Check visibility again to ensure it hasn't changed
      if (CHART_CONFIGS.brandAssociations.showConditions(baseAudience, selectedFilters)) {
        setData(prev => ({
          ...prev,
          brandAssociations: brandData
        }));
      }
    } catch (err) {
      console.error('Error fetching brand associations:', err);
      setError(err);
    } finally {
      setLoadingStates(prev => ({ ...prev, brands: false }));
    }
  };

  const fetchGeography = async () => {
    if (!isInitialized) return;
    try {
      setLoadingStates(prev => ({ ...prev, geography: true }));
      
      // Fetch base geography
      const baseResponse = await ApiService.getAudienceInsightsGeographyBase(baseAudience);
      const baseData = baseResponse.data.data || baseResponse.data;

      // Fetch comparison geography if in compare mode
      const comparisonCode = getComparisonCode();
      let comparisonData = null;
      
      if (comparisonCode) {
        const compareResponse = await ApiService.getAudienceInsightsGeographyCompare(comparisonCode, baseAudience);
        comparisonData = compareResponse.data.data || compareResponse.data;
      }

      setData(prev => ({
        ...prev,
        geography: {
          base: baseData,
          comparison: comparisonData
        }
      }));
    } catch (err) {
      console.error('Error fetching geography:', err);
      setError(err);
    } finally {
      setLoadingStates(prev => ({ ...prev, geography: false }));
    }
  };

  const fetchChannels = async () => {
    if (!isInitialized) return;
    try {
      setLoadingStates(prev => ({ ...prev, channels: true }));
      const comparisonCode = getComparisonCode();
      const response = await ApiService.getAudienceInsightsEngagementChannels(comparisonCode, baseAudience);
      const channelsData = response.data.data || response.data;
      
      // Channels data already includes comparison data in the response
      setData(prev => ({
        ...prev,
        channels: channelsData
      }));
    } catch (err) {
      console.error('Error fetching channels:', err);
      setError(err);
    } finally {
      setLoadingStates(prev => ({ ...prev, channels: false }));
    }
  };

  const fetchRecognition = async () => {
    if (!isInitialized) return;
    try {
      setLoadingStates(prev => ({ ...prev, recognition: true }));
      const comparisonCode = getComparisonCode();
      const response = await ApiService.getAudienceInsightsBrandRecognition(null, comparisonCode, baseAudience);
      const recognitionData = response.data.data || response.data;
      
      // Recognition data already includes comparison data in the response
      setData(prev => ({
        ...prev,
        recognition: recognitionData
      }));
    } catch (err) {
      console.error('Error fetching recognition:', err);
      setError(err);
    } finally {
      setLoadingStates(prev => ({ ...prev, recognition: false }));
    }
  };

  const fetchAthleteRecognition = async () => {
    if (!isInitialized) return;
    try {
      setLoadingStates(prev => ({ ...prev, athleteRecognition: true }));
      const athleteFilter = selectedFilters.find(f => f.type === 'athlete');
      if (!athleteFilter) {
        setData(prev => ({ ...prev, athleteRecognition: null }));
        return;
      }

      const compareFilters = selectedFilters.filter(f => f.type !== 'athlete');
      const response = await ApiService.getAudienceInsightsAthleteRecognition(
        athleteFilter.value,
        compareFilters
      );
      
      setData(prev => ({
        ...prev,
        athleteRecognition: response.data
      }));
    } catch (err) {
      console.error('Error fetching athlete recognition:', err);
      setError(err);
    } finally {
      setLoadingStates(prev => ({ ...prev, athleteRecognition: false }));
    }
  };

  const fetchComparisonData = async () => {
    if (!isInitialized) return;
    if (!compareMode || selectedFilters.length === 0) {
      return;
    }

    try {
      const comparisonCode = getComparisonCode();
      if (!comparisonCode) return;

      // Check if we should fetch brand associations
      const shouldFetchBrandAssociations = CHART_CONFIGS.brandAssociations.showConditions(baseAudience, selectedFilters);

      // Prepare API calls array
      const apiCalls = [
        ApiService.getAudienceInsightsDemographics(comparisonCode, baseAudience),
        ApiService.getAudienceInsightsGeographyCompare(comparisonCode, baseAudience),
        ApiService.getAudienceInsightsEngagementChannels(comparisonCode, baseAudience),
        ApiService.getAudienceInsightsBrandRecognition(null, comparisonCode, baseAudience)
      ];

      // Only add brand associations API call if we should fetch it
      if (shouldFetchBrandAssociations) {
        apiCalls.splice(1, 0, ApiService.getAudienceInsightsBrandAssociations(null, comparisonCode, baseAudience));
      }

      // Fetch comparison data
      const responses = await Promise.all(apiCalls);

      // Update data with comparison results
      setData(prev => {
        const [demographicsRes, ...otherRes] = responses;
        const updateData = {
          demographics: demographicsRes.data.data || demographicsRes.data,
          geography: {
            base: prev.geography.base,
            comparison: otherRes[shouldFetchBrandAssociations ? 1 : 0].data.data || otherRes[shouldFetchBrandAssociations ? 1 : 0].data
          },
          channels: otherRes[shouldFetchBrandAssociations ? 2 : 1].data.data || otherRes[shouldFetchBrandAssociations ? 2 : 1].data,
          recognition: otherRes[shouldFetchBrandAssociations ? 3 : 2].data.data || otherRes[shouldFetchBrandAssociations ? 3 : 2].data
        };

        // Only add brand associations if we fetched them
        if (shouldFetchBrandAssociations) {
          updateData.brandAssociations = otherRes[0].data.data || otherRes[0].data;
        }

        return {
          ...prev,
          ...updateData
        };
      });
    } catch (err) {
      console.error('Error fetching comparison data:', err);
      setError(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isInitialized) return;
      try {
        // Check which charts should be visible
        const newVisibleCharts = {};
        Object.entries(CHART_CONFIGS).forEach(([key, config]) => {
          newVisibleCharts[key] = config.showConditions(baseAudience, selectedFilters);
        });
        setVisibleCharts(newVisibleCharts);

        // Only fetch data for visible charts
        const fetchPromises = [];
        
        if (newVisibleCharts.demographics) {
          fetchPromises.push(fetchDemographics());
        }
        if (newVisibleCharts.geography) {
          fetchPromises.push(fetchGeography());
        }
        if (newVisibleCharts.mediaUsage) {
          fetchPromises.push(fetchChannels());
        }
        if (newVisibleCharts.brandRecognition) {
          fetchPromises.push(fetchRecognition());
        }
        if (newVisibleCharts.athleteRecognition) {
          fetchPromises.push(fetchAthleteRecognition());
        }
        if (newVisibleCharts.brandAssociations) {
          fetchPromises.push(fetchBrandAssociations());
        }

        await Promise.all(fetchPromises);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err);
      }
    };

    fetchData();
  }, [baseAudience, selectedFilters, isInitialized]); // Re-fetch when baseAudience or filters change

  // Separate effect for comparison data
  useEffect(() => {
    const fetchComparisonData = async () => {
      if (!isInitialized) return;
      if (!compareMode || selectedFilters.length === 0) {
        return;
      }

      try {
        const comparisonCode = getComparisonCode();
        if (!comparisonCode) return;

        // Check if brand associations should be visible
        const shouldFetchBrandAssociations = CHART_CONFIGS.brandAssociations.showConditions(baseAudience, selectedFilters);

        // Prepare API calls array
        const apiCalls = [];
        
        // Only add API calls for visible charts
        if (CHART_CONFIGS.demographics.showConditions(baseAudience, selectedFilters)) {
          apiCalls.push(ApiService.getAudienceInsightsDemographics(comparisonCode, baseAudience));
        }
        if (CHART_CONFIGS.geography.showConditions(baseAudience, selectedFilters)) {
          apiCalls.push(ApiService.getAudienceInsightsGeographyCompare(comparisonCode, baseAudience));
        }
        if (CHART_CONFIGS.mediaUsage.showConditions(baseAudience, selectedFilters)) {
          apiCalls.push(ApiService.getAudienceInsightsEngagementChannels(comparisonCode, baseAudience));
        }
        if (CHART_CONFIGS.brandRecognition.showConditions(baseAudience, selectedFilters)) {
          apiCalls.push(ApiService.getAudienceInsightsBrandRecognition(null, comparisonCode, baseAudience));
        }
        if (shouldFetchBrandAssociations) {
          apiCalls.push(ApiService.getAudienceInsightsBrandAssociations(null, comparisonCode, baseAudience));
        }

        // Fetch comparison data
        const responses = await Promise.all(apiCalls);

        // Update data with comparison results
        setData(prev => {
          const updateData = { ...prev };
          let responseIndex = 0;

          if (CHART_CONFIGS.demographics.showConditions(baseAudience, selectedFilters)) {
            updateData.demographics = responses[responseIndex++].data.data || responses[responseIndex - 1].data;
          }
          if (CHART_CONFIGS.geography.showConditions(baseAudience, selectedFilters)) {
            updateData.geography = {
              base: prev.geography.base,
              comparison: responses[responseIndex++].data.data || responses[responseIndex - 1].data
            };
          }
          if (CHART_CONFIGS.mediaUsage.showConditions(baseAudience, selectedFilters)) {
            updateData.channels = responses[responseIndex++].data.data || responses[responseIndex - 1].data;
          }
          if (CHART_CONFIGS.brandRecognition.showConditions(baseAudience, selectedFilters)) {
            updateData.recognition = responses[responseIndex++].data.data || responses[responseIndex - 1].data;
          }
          if (shouldFetchBrandAssociations) {
            updateData.brandAssociations = responses[responseIndex++].data.data || responses[responseIndex - 1].data;
          }

          return updateData;
        });
      } catch (err) {
        console.error('Error fetching comparison data:', err);
        setError(err);
      }
    };

    fetchComparisonData();
  }, [compareMode, selectedFilters, baseAudience, isInitialized]);

  return { loadingStates, data, error, visibleCharts };
};

const AudienceInsights = () => {
  const { isDarkMode } = useThemeContext();
  const [compareMode, setCompareMode] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [baseAudience, setBaseAudience] = useState(() => {
    try {
      const saved = localStorage.getItem(STORAGE_KEY);
      if (saved) {
        const { baseAudience } = JSON.parse(saved);
        return baseAudience || 'tenant_brand';
      }
    } catch (e) {
      console.error('Error loading saved state:', e);
    }
    return 'tenant_brand';
  });
  const [tenantBrand, setTenantBrand] = useState({ code: 'tenant_brand', name: 'Loading...' });
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const fetchTenantBrand = async () => {
      try {
        const response = await ApiService.getTenantBrand();
        setTenantBrand(response.data);
        // Set the base audience to the tenant's brand code if not already set
        if (baseAudience === 'tenant_brand') {
          setBaseAudience(response.data.code);
        }
        setIsInitialized(true);
      } catch (error) {
        console.error('Error fetching tenant brand:', error);
        // Keep the default values if there's an error
        setIsInitialized(true);
      }
    };
    fetchTenantBrand();
  }, []);

  const { loadingStates, data, error, visibleCharts } = useAudienceData(
    compareMode, 
    selectedFilters, 
    baseAudience,
    isInitialized
  );

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify({
      compareMode,
      selectedFilters,
      baseAudience
    }));
  }, [compareMode, selectedFilters, baseAudience]);

  const handleFilterChange = (newFilters) => {
    setSelectedFilters(newFilters);
    if (newFilters.length > 0 && !compareMode) {
      setCompareMode(true);
    }
    if (newFilters.length === 0 && compareMode) {
      setCompareMode(false);
    }
  };

  const handleBaseAudienceChange = (newBaseAudience) => {
    setBaseAudience(newBaseAudience);
    // If switching to 'everyone', we need a comparison filter to show brand recognition/associations
    if (newBaseAudience === 'everyone' && !compareMode && selectedFilters.length === 0) {
      setCompareMode(true);
    }
  };

  const handleCompareModeChange = () => {
    setCompareMode(!compareMode);
  };

  const getBaseAudienceName = () => {
    if (baseAudience === 'everyone') {
      return 'Everyone';
    }
    return tenantBrand.name;
  };

  const renderChartSection = (chartKey, chartConfig) => {
    // Don't render if chart shouldn't be visible
    if (!visibleCharts[chartKey]) {
      return null;
    }

    const Component = chartConfig.component;
    
    // Map the data key to the correct API response data
    const getChartData = (key) => {
      switch (key) {
        case 'demographics':
          return data?.demographics;
        case 'geography':
          return data?.geography;
        case 'brandAssociations':
          return data?.brandAssociations;
        case 'brandRecognition':
          return data?.recognition;
        case 'mediaUsage':
          return data?.channels;
        case 'athleteRecognition':
          return data?.athleteRecognition;
        default:
          return null;
      }
    };

    // Map loading states correctly
    const getLoadingState = (key) => {
      switch (key) {
        case 'mediaUsage':
          return loadingStates.channels;
        case 'brandRecognition':
          return loadingStates.recognition;
        default:
          return loadingStates[key];
      }
    };

    return (
      <Col md={chartConfig.gridWidth} key={chartKey} className="mb-4">
        <div className="chart-container">
          <Card className={isDarkMode ? 'dark' : ''}>
            <CardHeader>{chartConfig.title}</CardHeader>
            <CardBody>
              <Component
                data={getChartData(chartKey)}
                loading={getLoadingState(chartKey)}
                compareMode={compareMode && selectedFilters.length > 0}
                filterName={selectedFilters[0]?.rowTitle || selectedFilters[0]?.name}
                baseAudienceName={getBaseAudienceName()}
                selectedFilters={selectedFilters}
              />
            </CardBody>
          </Card>
        </div>
      </Col>
    );
  };

  if (error) {
    return <div>Error loading insights. Please try again later.</div>;
  }

  return (
    <div className="audience-insights">
      <Row className="mb-4">
        <Col>
          <FilterPanel
            selectedFilters={selectedFilters}
            onChange={handleFilterChange}
            compareMode={compareMode}
            onCompareModeChange={handleCompareModeChange}
            baseAudience={baseAudience}
            onBaseAudienceChange={handleBaseAudienceChange}
            tenantBrand={tenantBrand}
          />
        </Col>
      </Row>

      <Row>
        {Object.entries(CHART_CONFIGS).map(([key, config]) => renderChartSection(key, config))}
      </Row>
    </div>
  );
};

export default AudienceInsights;
