import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Card, CardBody, Row, Col, FormGroup, Button, Spinner } from 'reactstrap';
import ApiService from '../services/ApiService';

const leagueOptions = [
	/* { label: 'Eliteserien', value: 'eliteserien' },
	{ label: 'Obosligaen', value: 'obosligaen' }, */
	{ label: 'Eliteserien & Obosligaen', value: 'eliteserien_obosligaen' },
	{ label: 'Toppserien', value: 'toppserien' },
	{ label: 'Landslaget', value: 'landslaget' },
	{ label: 'Døds', value: 'døds' },
	{ label: 'Alpint', value: 'alpint' },
	{ label: 'Posten - Bring', value: 'posten_bring' },
	{ label: 'Olympiatoppen', value: 'olympiatoppen' },
	{ label: 'Team Aker Dæhlie', value: 'teamakerdæhlie' },
];

const AdminMeltwater = () => {
	const [league, setLeague] = useState(leagueOptions[0]);
	const [file, setFile] = useState(null);
	const [meltwaterJobs, setMeltwaterJobs] = useState(null);
	const [loading, setLoading] = useState({
		generateMeltwaterMetrics: false,
		importMeltwater: false,
	});

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		setFile(selectedFile);
	};

	const onSubmit = async () => {
		if (!file) {
			window.alert('Please upload a file.');
			return;
		}

		setLoading((prev) => ({
			...prev,
			importMeltwater: true,
		}));

		try {
			const formData = new FormData();
			formData.append('meltwater_excel', file);
			formData.append('league', league.value);

			await ApiService.importMeltwater(formData);
			window.alert('Success');
		} catch (err) {
			const errorMessage =
				err?.response?.data?.error ||
				'Something went wrong while uploading Meltwater data.';
			window.alert(errorMessage);
		}

		setLoading((prev) => ({
			...prev,
			importMeltwater: false,
		}));
	};

	const generateMeltwaterMetrics = async () => {
		setLoading((prev) => ({
			...prev,
			generateMeltwaterMetrics: true,
		}));

		try {
			await ApiService.generateMeltwaterMetrics();
		} catch (err) {
			window.alert('Something went wrong while starting Meltwater metrics');
		}

		setLoading((prev) => ({
			...prev,
			generateMeltwaterMetrics: false,
		}));

		getMeltwaterJobsCount();
	};

	const getMeltwaterJobsCount = async () => {
		const resMeltwaterJobsCount = await ApiService.meltwaterJobsCount();
		setMeltwaterJobs(resMeltwaterJobsCount.data);
	};

	useEffect(() => {
		getMeltwaterJobsCount();
	}, []);

	return (
		<Row className="mb-4 has-overlay">
			<Col sm={6} md={6}>
				<Card>
					<CardBody>
						<h5 className="card-title mb-4">Import Meltwater Excel</h5>
						<FormGroup>
							<Select
								className="react-select__container"
								classNamePrefix="react-select"
								options={leagueOptions}
								value={league}
								onChange={(newLeague) => setLeague(newLeague)}
							/>
						</FormGroup>
						<FormGroup>
							<label htmlFor="file-upload" className="custom-file-upload">
								<i className="ti-file mr-2"></i>
								{file ? file.name : 'Select file'}
							</label>
							<input
								onChange={handleFileChange}
								id="file-upload"
								type="file"
								accept=".xlsx, .xls"
							/>
							{file && (
								<Button
									size="sm"
									className="ml-4"
									color="transparent"
									onClick={() => setFile(null)}
								>
									<i className="ti-close" />
								</Button>
							)}
						</FormGroup>
						<Button
							onClick={onSubmit}
							disabled={loading.importMeltwater || !file}
							color="primary"
						>
							Import Meltwater{' '}
							{loading.importMeltwater && <Spinner color="white" size="sm" />}
						</Button>
					</CardBody>
				</Card>
			</Col>
			<Col>
				<Card>
					<CardBody>
						<h5 className="card-title mb-4">Meltwater Jobs</h5>
						<p>There are {meltwaterJobs} meltwater jobs currently running</p>
						<p>Generate meltwater metrics on the last two months</p>
						<Button
							onClick={generateMeltwaterMetrics}
							color="primary"
							disabled={loading.generateMeltwaterMetrics}
						>
							Generate Meltwater Metrics{' '}
							{loading.generateMeltwaterMetrics && (
								<Spinner color="white" size="sm" />
							)}
						</Button>
					</CardBody>
				</Card>
			</Col>
		</Row>
	);
};

export default AdminMeltwater;
