import { Card, CardBody, Col, Row } from 'reactstrap';
import React, { useState } from 'react';
import PartnerTabs from '../components/PartnerTabs';
import OverallMetric from '../components/OverallMetric';
import { usePartnerDividedReach, usePartnerReach, usePartnerReachValue, useSponsorValueDividedYear } from '../query/hooks';
import LineGraph from '../components/LineGraph';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ROIWidget from '../components/ROIWidget';
import SummaryDonutChart from '../components/SummaryDonutChart';
import SourceDividedValueChart from '../components/SourceDividedValueChart';
import BestPostsCards from '../components/BestPostsCards';

const PartnerAssociation = ({ selectedCurrency }) => {
  const [selectedPartnerId, setSelectedPartnerId] = useState();

  const { t } = useTranslation();

  const onClickTab = (tabIndex, partnerId) => {
    setSelectedPartnerId(partnerId)
  };

  return (
      <div className="container-fluid pt-4">
        <Col xs="12">
          <PartnerTabs onClickTab={onClickTab} />
        </Col>
        <Row>
          <Col sm={12} md={6} className="mb-4">
            <Card className="mini-stat bg-card text-white h-100" style={{minHeight: 400}}>
              <CardBody className="d-flex flex-column">
                <h4 className="card-title mb-4">{t("ROI")}</h4>
                <ROIWidget />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6} className="mb-4">
            <Card className="mini-stat bg-card text-white" style={{minHeight: 400}}>
              <CardBody>
                <h4 className="card-title mb-4">{t("Value")}</h4>
                <SummaryDonutChart useQuery={useSponsorValueDividedYear} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              numeralFormat="0.0a"
              metric="partner-value"
              isCurrency
              params={{partnerId: selectedPartnerId}}
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              metric="partner-exposures"
              params={{partnerId: selectedPartnerId}}
            />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric
              followCalender
              numeralFormat="0.0a"
              metric="partner-views"
              params={{partnerId: selectedPartnerId}}
            />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric
              followCalender
              metric="partner-engagement"
              append="min"
              params={{partnerId: selectedPartnerId}}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Visninger per partner")}
                </h4>
                <LineGraph
                  useGraphQuery={usePartnerReach}
                  defaultActiveTab={0}
                  queryParams={[selectedPartnerId]}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Synlighetsverdi per partner")} ({selectedCurrency.currency})
                </h4>
                <LineGraph
                  useGraphQuery={usePartnerReachValue}
                  defaultActiveTab={0}
                  queryParams={[selectedPartnerId]}
                  multipliers={[1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Visninger per kanal")}
                </h4>
                <LineGraph
                  useGraphQuery={usePartnerDividedReach}
                  queryParams={[selectedPartnerId]}
                  defaultActiveTab={0}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Sponsorverdi per medie")} (
                  {selectedCurrency.currency})
                </h4>
                <SourceDividedValueChart partnerId={selectedPartnerId}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              numeralFormat="0a"
              metric="partner-post-detection-count"
              params={{partnerId: selectedPartnerId}}
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              numeralFormat="0a"
              metric="partner-avg-exposure-value"
              params={{partnerId: selectedPartnerId}}
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              numeralFormat="0a"
              metric="partner-avg-engagement"
              params={{partnerId: selectedPartnerId}}
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              numeralFormat="0a"
              metric="partner-avg-engagement-value"
              params={{partnerId: selectedPartnerId}}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col md={3} sm={6} className="mb-4"><Card className="mini-stat bg-card text-white h-100"><CardBody>ANTALL POSTER MED LOGOEKSPONERING</CardBody></Card></Col>
          <Col md={3} sm={6} className="mb-4"><Card className="mini-stat bg-card text-white h-100"><CardBody>SNITT EKSPONERINGSVERDI PER POST</CardBody></Card></Col>
          <Col md={3} sm={6} className="mb-4"><Card className="mini-stat bg-card text-white h-100"><CardBody>SNITT ENGASJEMENT PER POST</CardBody></Card></Col>
          <Col md={3} sm={6} className="mb-4"><Card className="mini-stat bg-card text-white h-100"><CardBody>SNITT ENGASJEMENTSVERDI PER POST</CardBody></Card></Col>
        </Row> */}
        <Row>
          <Col md={12} className="mb-4">
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">{t("Beste poster sosiale medier")}</h4>
                <BestPostsCards params={{variant: 'partner', variantId: selectedPartnerId}} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
};


const mapStateToProps = state => ({
  selectedCurrency: state.currency.selectedCurrency,
});

export default connect(mapStateToProps)(PartnerAssociation);