import React, { useEffect, useState } from 'react'
import TopBar from './TopBar'
import Footer from './Footer'
import { connect } from 'react-redux'
import { useHistory, useLocation, Route, Switch } from 'react-router-dom'
import { Nav, NavItem } from 'reactstrap'
import './AdminLayout.css'
import { useThemeContext } from '../contexts/ThemeContext'
import AdminPanel from '../views/AdminPanel'
import MediaPanel from '../views/MediaPanel'
import EventsPanel from '../views/EventsPanel'

const AdminLayout = ({toggleRightSidebar, topbarTheme, tenant, children, date}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [activeTab, setActiveTab] = useState('admin');
  const history = useHistory();
  const location = useLocation();
  const { isDark } = useThemeContext();

  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  useEffect(() => {
    window.scrollTo(0,0);
    
    // Set active tab based on current URL
    const path = location.pathname;
    console.log('Current path in useEffect:', path);
    if (path.includes('/admin/media')) {
      setActiveTab('media');
    } else if (path.includes('/admin/events')) {
      setActiveTab('events');
    } else if (path === '/admin') {
      setActiveTab('admin');
    }
  }, [location.pathname]);

  const handleTabClick = (tabName, path) => {
    console.log(`Tab clicked: ${tabName}, navigating to: ${path}`);
    if (activeTab !== tabName) {
      setActiveTab(tabName);
      console.log(`Setting active tab to: ${tabName}`);
      history.push(path);
      console.log(`Navigation executed to: ${path}`);
    } else {
      console.log(`Already on tab: ${tabName}, no navigation needed`);
    }
  };

  // For debugging
  console.log('Rendering AdminLayout with path:', location.pathname);
  console.log('Active tab:', activeTab);

  return (
    <div id="layout-wrapper" className="admin-layout">
      <TopBar
        theme={topbarTheme}
        isMenuOpened={isMenuOpened}
        toggleRightSidebar={toggleRightSidebar}
        openLeftMenuCallBack={openMenu}
        tenant={tenant}
      />
      <div className="admin-navbar">
        <Nav tabs className="border-0">
          <NavItem>
            <button 
              className={`admin-tab ${activeTab === 'admin' ? 'active' : ''}`}
              onClick={() => handleTabClick('admin', '/admin')}
            >
              Admin
            </button>
          </NavItem>
          <NavItem>
            <button 
              className={`admin-tab ${activeTab === 'media' ? 'active' : ''}`}
              onClick={() => handleTabClick('media', '/admin/media')}
            >
              Media
            </button>
          </NavItem>
          <NavItem>
            <button 
              className={`admin-tab ${activeTab === 'events' ? 'active' : ''}`}
              onClick={() => handleTabClick('events', '/admin/events')}
            >
              Events
            </button>
          </NavItem>
        </Nav>
      </div>
      <div className="main-content" id="admin-layout">
        <div className="page-content" style={{ padding: '1rem' }}>
          <Switch>
            <Route exact path="/admin" component={AdminPanel} />
            <Route exact path="/admin/media" component={MediaPanel} />
            <Route exact path="/admin/events" component={EventsPanel} />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = state => ({
  tenant: state.auth.tenant,
  date: state.date
})

export default connect(mapStateToProps, {})(AdminLayout)