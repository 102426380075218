import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import OverallMetric from "../components/OverallMetric";
import InfoTooltip from "../components/InfoTooltip";
import DemographicsChart from "../components/DemographicsChart";
import Geography from "../components/Geography";
import BrandSources from "../components/BrandSources";
import BestSocialMediaPostBubbles from "../components/BestSocialMediaPostBubbles";
import PerformingSocialMediaPosts from "../components/PerformingSocialMediaPosts";
import VisibilityGraph from "../components/VisibilityGraph";
import EngagementGraph from "../components/EngagementGraph";

const DashboardAssiciation = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="container-fluid pt-4">
        <Row>
          <Col xl={3} md={6} className="mb-4">
            <OverallMetric
              metric="followers"
              tooltip={t('Viser totalt antall følgere i sosiale kanaler. Prosent-tallet viser trendutvikling sammenlignet med forrige måned. Merk at det her kan være dobbeltdekning mellom Facebook og Instagram.')}
            />
          </Col>
          <Col xl={3} md={6} className="mb-4">
            <OverallMetric
              metric="engagement"
              followCalender
              tooltip={t(
                "Viser totalt engasjement som er skapt inklusive antall likes, kommentarer og delinger i sosiale kanaler, samt nettsidebesøk med mer enn to sidevisninger og antall personer som har sett hele kamper på TV. Prosent-tallet viser trendutvikling sammenlignet med forrige måned."
              )}
            />
          </Col>
					<Col xl={3} md={6} className="mb-4">
						<OverallMetric
							followCalender
							metric="press-mentions"
							tooltip={t('Totalt antall saker i riks- og lokalaviser enten på nett eller i print hvor klubben er omtalt så langt i inneværende år.')}
							noTrend
						/>
					</Col>
					<Col xl={3} md={6} className="mb-4">
						<OverallMetric
							followCalender
							metric="sentiment"
							tooltip={t('Andel av pressesakene som er blitt registrert som positiv omtale. Ofte er ca 40-70% av pressesakene registrert som “nøytrale”. Andel positiv omtale vil derfor sjelden være mer enn 50%.')}
							noTrend
							numeralFormat="0%"
							variant="ratio-bar"
						/>
					</Col>
        </Row>
        <Row>
          <Col sm={12} md={6} className="mb-4">
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">{t('Rekkevidde')}</h4>
                <VisibilityGraph />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6} className="mb-4">
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">{t('Engasjement')}</h4>
                <EngagementGraph />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} className="mb-4">
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t('Alder og kjønn')}
                  <InfoTooltip tooltip={t('Viser demografisk nedbrytning av følgere i forhold til alder/livsfase og kjønn.')} />
                </h4>
                <DemographicsChart />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6} className="mb-4">
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t('Geografi')}
                  <InfoTooltip tooltip={t('Viser kommunene der følgere bor. Dette er ikke komplett data, mange brukere har ikke lokasjonsdata tilgjengelig.')} />
                </h4>
                <Geography />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
					<Col sm={12} className="mb-4">
						<Card className="mini-stat bg-card text-white">
							<CardBody>
								<h4 className="card-title mb-4">
									{t('Presseomtale')}
									<InfoTooltip tooltip={t('Viser antall saker i riksdekkende og lokale nyhetsmedierhvor klubb, trener, eller arena er nevnt i forbindelse med fotball eller liga.')} />
								</h4>
								<BrandSources />
							</CardBody>
						</Card>
					</Col>
          {/* TODO <Col sm={12} md={6} className="mb-4">
            <Card className="mini-stat bg-card text-white">
              <CardBody>VERDISKAPNING PER PROSJEKT</CardBody>
            </Card>
          </Col> */}
        </Row>
        {/* TODO <Row>
          <Col xs={12} className="mb-4">
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                Sosialtidslinje
              </CardBody>
            </Card>
          </Col>
        </Row> */}
        <Row className="mb-4">
          <Col md={12}>
            <BestSocialMediaPostBubbles />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-4">
            <PerformingSocialMediaPosts type="BESTE" />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-4">
            <PerformingSocialMediaPosts type="SVAKESTE" />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DashboardAssiciation;
