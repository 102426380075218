import React, { useState } from "react";
import numeral from "numeral";
import "numeral/locales";
import { Col, Nav, NavLink, Row, Spinner } from "reactstrap";
import useBestPosts from "../query/hooks/useBestPosts";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
numeral.locale("no");

const BestPostsCards = ({params}) => {
  const tabs = [
    { label: 'Eksponeringer', value: 'impression', order: false, },
    { label: 'Engasjement', value: 'engagement', order: false },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const { data, isLoading: isFetching } = useBestPosts(activeTab, params);
  const {t} = useTranslation();

  const posts = data?.slice(0, 10) ?? [];

  return (
    <>
      {(isFetching) && <Spinner color="primary" />}
      <Nav pills className="navtab-bg center-pills mb-4">
        <NavLink
          href="#"
          className={classNames({ active: activeTab.value === tabs[0].value })}
          onClick={() => setActiveTab(tabs[0])}
        >
          {t(tabs[0].label)}
        </NavLink>
        <NavLink
          href="#"
          className={classNames({ active: activeTab.value === tabs[1].value })}
          onClick={() => setActiveTab(tabs[1])}
        >
          {t(tabs[1].label)}
        </NavLink>
      </Nav>
      <Row className="mb-4 px-4">
        {posts.length > 0 && (
          <div className="flex flex-wrap gap-2">
          {posts.map((post) => (
            <Col key={post.id} xs={12} sm={6} md={4} lg={3} className="mb-3">
              <a href={post.permalink} target="_blank" rel="noreferrer" className="post">
                <div className="post-card">
                  <div className="post-card-image">
                    {post.media_type === 'VIDEO' ? (
                      <div className="post-card-media-video">
                        <video src={post.s3_url} alt="" className="post-card-media" />
                        <i className='fa fa-play post-card-media-video-icon'/>
                      </div>
                    ) : (
                      <img src={post.s3_url} alt="" className="post-card-media" />
                    )}
                    <div className="post-card-image-icon">
                      {post.media === 'facebook' && <div style={{ backgroundColor: '#117197' }}><FaFacebook /></div>}
                      {post.media === 'instagram' && <div style={{ backgroundColor: '#E9B42C'}}><FaInstagram /></div>}
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="post-card-message">
                      {post.message}
                    </div>
                    <div className="post-card-footer">
                      <span>👀 {post.impression}</span>
                      <span>👏 {post.engagement}</span>
                      <span>💰 {post.value}</span>
                    </div>
                  </div>
                </div>
              </a>
            </Col>
          ))}
        </div>
      )}
      </Row>
    </>
  );
};

export default BestPostsCards;
