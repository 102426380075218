import React, { useRef, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMap from "highcharts/modules/map";
import mapNorway from '@highcharts/map-collection/countries/no/no-all.geo.json';
import { useThemeContext } from '../../../contexts/ThemeContext';

highchartsMap(Highcharts);

const GeographyMap = ({ data, compareMode = false, filterName = null, baseAudienceName }) => {
  const { isDarkMode } = useThemeContext();
  const baseChartRef = useRef(null);
  const comparisonChartRef = useRef(null);

  const getMapOptions = (seriesData, title, color, chartRef, otherChartRef) => {
    const chartStyles = getComputedStyle(document.documentElement);
    const backgroundColor = chartStyles.getPropertyValue('--chart-background').trim();
    const borderColor = chartStyles.getPropertyValue('--chart-border').trim();

    
    const getDataLabelStyle = () => ({
      enabled: true,
      useHTML: false,
      style: {
        fontSize: '11px',
        fontWeight: 'bold',
        textOutline: isDarkMode ? '2px #000000' : '1px #FFFFFF'
      },
      formatter: function() {
        if (this.point.value > 0) {
          const shadowStyle = isDarkMode ? 
            '2px 2px 3px #000, -2px -2px 3px #000, 2px -2px 3px #000, -2px 2px 3px #000' :
            '2px 2px 1px #fff, -2px -2px 1px #fff, 2px -2px 1px #fff, -2px 2px 1px #fff';
          
          return `<span style="color: ${isDarkMode ? '#FFFFFF' : '#000000'}; 
                              text-shadow: ${shadowStyle}; 
                              font-weight: bold;
                              font-size: 11px;">
                    ${this.point.value.toFixed(1)}%
                  </span>`;
        }
        return null;
      }
    });
    
    return {
      chart: {
        map: mapNorway,
        backgroundColor: backgroundColor,
        style: {
          fontFamily: 'inherit'
        },
        events: {
          load: function() {
            if (chartRef) {
              chartRef.current = this;
            }
          }
        }
      },
      mapView: {
        events: {
          afterSetExtremes: function(e) {
            if (otherChartRef?.current && !this.chart.isUpdating) {
              this.chart.isUpdating = true;
              otherChartRef.current.mapView.setView(
                this.center,
                this.zoom,
                false
              );
              otherChartRef.current.redraw();
              this.chart.isUpdating = false;
            }
          }
        }
      },
      xAxis: {
        events: {
          afterSetExtremes: function(e) {
            if (otherChartRef?.current && !e.trigger) {
              otherChartRef.current.xAxis[0].setExtremes(e.min, e.max, false);
              otherChartRef.current.yAxis[0].setExtremes(
                otherChartRef.current.yAxis[0].min,
                otherChartRef.current.yAxis[0].max,
                true
              );
            }
          }
        }
      },
      yAxis: {
        events: {
          afterSetExtremes: function(e) {
            if (otherChartRef?.current && !e.trigger) {
              otherChartRef.current.yAxis[0].setExtremes(e.min, e.max, false);
              otherChartRef.current.xAxis[0].setExtremes(
                otherChartRef.current.xAxis[0].min,
                otherChartRef.current.xAxis[0].max,
                true
              );
            }
          }
        }
      },
      mapNavigation: {
        enabled: true,
        enableDoubleClickZoomTo: true,
        enableMouseWheelZoom: false,
        enableTouchZoom: false,
        buttonOptions: {
          theme: {
            fill: backgroundColor,
            stroke: borderColor,
            style: {
              color: "var(--text-primary)"
            },
            states: {
              hover: {
                fill: borderColor
              },
              select: {
                fill: borderColor
              }
            }
          }
        }
      },
      title: {
        text: title,
        style: {
          color: "var(--text-primary)",
          fontSize: '14px'
        }
      },
      credits: {
        enabled: false
      },
      colorAxis: {
        min: 0,
        max: 100,
        stops: [
          [0, color === '#FFB800' ? '#FFF3D4' : '#E3F2FD'],    // Start with a light base
          [0.15, color === '#FFB800' ? '#FFE082' : '#64B5F6'], // Light
          [0.3, color === '#FFB800' ? '#FFD54F' : '#2196F3'],  // Medium-light
          [0.5, color === '#FFB800' ? '#FFC107' : '#1976D2'],  // Medium
          [0.7, color === '#FFB800' ? '#FFB300' : '#1565C0'],  // Medium-dark
          [1, color === '#FFB800' ? '#FF8F00' : '#0D47A1']     // Dark
        ],
        labels: {
          style: {
            color: "var(--text-primary)"
          }
        }
      },
      legend: {
        title: {
          text: 'Audience %',
          style: {
            color: "var(--text-primary)"
          }
        },
        itemStyle: {
          color: "var(--text-primary)"
        }
      },
      plotOptions: {
        series: {
          point: {
            events: {
              dblclick: function() {
                const point = this;
                const chart = this.series.chart;
                
                if (otherChartRef?.current) {
                  const otherSeries = otherChartRef.current.series[0];
                  const otherPoint = otherSeries.points.find(p => p['hc-key'] === point['hc-key']);
                  if (otherPoint) {
                    // Temporarily disable animations
                    const originalAnimation = otherChartRef.current.options.plotOptions?.series?.animation;
                    otherChartRef.current.options.plotOptions.series.animation = false;
                    
                    // Let the first chart zoom complete
                    setTimeout(() => {
                      const zoom = chart.mapView.zoom;
                      const center = chart.mapView.center;
                      
                      // Update view without animation
                      otherChartRef.current.mapView.setView(center, zoom, false);
                      
                      // Force immediate redraw
                      otherChartRef.current.redraw(false);
                      
                      // Re-enable animations
                      otherChartRef.current.options.plotOptions.series.animation = originalAnimation;
                      
                      // Force another redraw after a short delay
                      setTimeout(() => {
                        otherChartRef.current.reflow();
                        otherChartRef.current.redraw();
                      }, 50);
                    }, 100);
                  }
                }
              },
              mouseOver: function() {
                const point = this;
                if (otherChartRef?.current) {
                  const otherSeries = otherChartRef.current.series[0];
                  const otherPoint = otherSeries.points.find(p => p['hc-key'] === point['hc-key']);
                  if (otherPoint) {
                    otherPoint.update({
                      color: color,
                      brightness: 0.2
                    }, false);
                    otherChartRef.current.redraw();
                  }
                }
              },
              mouseOut: function() {
                const point = this;
                if (otherChartRef?.current) {
                  const otherSeries = otherChartRef.current.series[0];
                  const otherPoint = otherSeries.points.find(p => p['hc-key'] === point['hc-key']);
                  if (otherPoint) {
                    const value = otherPoint.value;
                    const colorAxis = otherChartRef.current.colorAxis[0];
                    const normalColor = colorAxis.toColor(value);
                    otherPoint.update({
                      color: normalColor,
                      brightness: 0
                    }, false);
                    otherChartRef.current.redraw();
                  }
                }
              }
            }
          }
        }
      },
      series: [{
        data: seriesData,
        name: 'Region',
        joinBy: ['hc-key', 0],
        states: {
          hover: {
            color: color,
            brightness: 0.2
          }
        },
        dataLabels: getDataLabelStyle(),
        events: {
          mouseOut: function() {
            if (otherChartRef?.current) {
              otherChartRef.current.series[0].points.forEach(point => {
                const value = point.value;
                const colorAxis = otherChartRef.current.colorAxis[0];
                const normalColor = colorAxis.toColor(value);
                point.update({
                  color: normalColor,
                  brightness: 0
                }, false);
              });
              otherChartRef.current.redraw();
            }
          }
        }
      }]
    };
  };

  const getSeriesData = (useComparison = false) => {
    if (!data) {
      return [];
    }

    // Get the correct data based on whether we're showing comparison or base
    const dataToUse = useComparison ? data.comparison : data.base;
    if (!dataToUse) {
      return [];
    }

    const regionMap = {
      '1': ['no-no', 'no-tf'],           // Nord-Norge (Nordland, Troms og Finnmark)
      '2': ['no-td'],                    // Midt-Norge (Trøndelag)
      '3': ['no-vl', 'no-mr', 'no-ro'],  // Vestlandet (Vestland, Møre og Romsdal, Rogaland)
      '4': ['no-vk', 'no-in'],           // Østlandet (Viken, Innlandet)
      '5': ['no-ag'],                    // Sørlandet inkludert TeVe (Agder)
      '6': ['no-os']                     // Oslo
    };

    const seriesData = dataToUse.reduce((acc, item) => {
      const codes = regionMap[item.region];
      if (codes) {
        const percentage = parseFloat(item.all_percentage) || 0;
        codes.forEach(code => {
          acc[code] = percentage;
        });
      }
      return acc;
    }, {});

    return Object.entries(seriesData);
  };

  if (!compareMode) {
    return (
      <div style={{ minHeight: '400px' }}>
        <HighchartsReact
          key={isDarkMode ? 'dark' : 'light'}
          highcharts={Highcharts}
          options={getMapOptions(getSeriesData(false), baseAudienceName, '#FFB800', null, null)}
          constructorType={'mapChart'}
        />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      <div style={{ flex: 1 }}>
        <HighchartsReact
          key={isDarkMode ? 'dark' : 'light'}
          highcharts={Highcharts}
          options={getMapOptions(getSeriesData(false), baseAudienceName, '#FFB800', baseChartRef, comparisonChartRef)}
          constructorType={'mapChart'}
        />
      </div>
      <div style={{ flex: 1 }}>
        <HighchartsReact
          key={isDarkMode ? 'dark' : 'light'}
          highcharts={Highcharts}
          options={getMapOptions(getSeriesData(true), filterName || 'Comparison', '#2caffe', comparisonChartRef, baseChartRef)}
          constructorType={'mapChart'}
        />
      </div>
    </div>
  );
};

export default GeographyMap;
