
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { ATHLETE, GAINED_FOLLOWERS } from "../keys";
import { format } from "date-fns";

const useGainedFollowers = () => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }

  return useQuery({
    queryKey: [ATHLETE, GAINED_FOLLOWERS, tenant.id, startDate, endDate],
    queryFn: () => ApiService.getGainedFollowers(since, until),
    select: (data) => data.data,
    placeholderData: { data: {} },
  });
};

export default useGainedFollowers;