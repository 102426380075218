import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { PROJECTS } from "../keys";

const useProjects = () => {
  const tenant = useSelector((state) => state.auth.tenant);

  return useQuery({
    queryKey: [PROJECTS, tenant.id],
    queryFn: () => ApiService.getProjects(),
    select: (data) => JSON.parse(JSON.stringify(data.data)),
    placeholderData: {data: []},
  });
};
export default useProjects;
