import { Card, CardBody, Col, Row } from 'reactstrap';
import React, { useState } from 'react';
import SummaryDonutChart from '../components/SummaryDonutChart';
import { useProjectViews, useProjectEngagement } from '../query/hooks';
import { useTranslation } from 'react-i18next';
import ProjectsTabs from '../components/ProjectTabs';
import OverallMetric from '../components/OverallMetric';
import InfoTooltip from '../components/InfoTooltip';
import PressCountBarChart from '../components/PressCountBarChart';
import LineGraph from '../components/LineGraph';
import useProjectValueDividedYear from '../query/hooks/useProjectValueDividedYear';
import BestPostsCards from '../components/BestPostsCards';
import Table from '../components/Table';
import useProjectOverview from '../query/hooks/useProjectOverview';

const Projects = () => {
  const [selectedProjectId, setSelectedPartnerId] = useState();
  const {t} = useTranslation();

  const onClickTab = (tabIndex, projectId) => {
    setSelectedPartnerId(projectId)
  };

  const params = { projectId: selectedProjectId };

  return (
      <div className="container-fluid pt-4">
        <Col xs="12">
          <ProjectsTabs onClickTab={onClickTab} />
        </Col>
        <Row className="mb-4">
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              metric="project-reach"
              params={params}
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              metric="project-reach-value"
              params={params}
              isCurrency
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              metric="project-engagement"
              params={params}
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              metric="project-engagement-value"
              params={params}
              isCurrency
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} className="mb-4">
            <Card className="mini-stat bg-card text-white" style={{minHeight: 400}}>
              <CardBody>
                <h4 className="card-title mb-4">{t("Value")}</h4>
                <SummaryDonutChart useQuery={useProjectValueDividedYear} queryParams={params} />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6} className="mb-4">
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">{t("Overview")}</h4>
                <Table
                  useQuery={useProjectOverview}
                  params={[params.projectId]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={3} sm={6} className="mb-4">
            <OverallMetric
              followCalender
              metric="project-post-count"
              params={params}
            />
          </Col>
          <Col md={3} sm={6} className="mb-4">
            <OverallMetric
              followCalender
              metric="project-article-count"
              params={params}
            />
          </Col>
          <Col md={3} sm={6} className="mb-4">
            <OverallMetric
              followCalender
              metric="project-avg-engagement-post"
              params={params}
            />
          </Col>
          <Col md={3} sm={6} className="mb-4">
            <OverallMetric
              followCalender
              metric="project-avg-engagement-post-value"
              params={params}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-4">
            <Card className="mini-stat bg-card text-white mb-4">
              <CardBody>
                <h4 className="card-title mb-4">{t("VISNINGER")}</h4>
                <LineGraph
                  useGraphQuery={useProjectViews}
                  defaultActiveTab={1}
                  queryParams={[Object.values(params)]}
                />
              </CardBody>
            </Card>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">{t("Engasjement")}</h4>
                <LineGraph
                  useGraphQuery={useProjectEngagement}
                  defaultActiveTab={1}
                  queryParams={[Object.values(params)]}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={6} className="bg-card mb-4">
            <Card className="mini-stat bg-card text-white">
              <CardBody>
              <h4 className="card-title mb-4">
									{t('Omtale per medie')}
									<InfoTooltip tooltip={t('Viser medieomtale og presseoppslag fordelt på ulike print (merket PDF) og nettaviser. Saker bak betalingsmur er merket med "login" eller "pluss".')} />
								</h4>
								<PressCountBarChart params={{isProject: true, projectId: selectedProjectId}} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mb-4">
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
									{t('Beste poster sosiale medier')}
									<InfoTooltip tooltip={t('Viser medieomtale og presseoppslag fordelt på ulike print (merket PDF) og nettaviser. Saker bak betalingsmur er merket med "login" eller "pluss".')} />
								</h4>
                <BestPostsCards params={{variant: 'project', variantId: selectedProjectId}} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
};

export default Projects;