import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import BoxedMetric from "../components/BoxedMetric";
import numeral from "numeral";
import LineGraph from "../components/LineGraph";
import useMediaValuePeriod from "../query/hooks/useMediaValuePeriod";
import useReachValuePeriod from "../query/hooks/useReachValuePeriod";
import useEngagementValuePeriod from "../query/hooks/useEngagementValuePeriod";
import AthleteSponsorValue from "../components/AthleteSponsorValue";
import { useTranslation } from "react-i18next";


const ValuePerson = () => {
  const {t} = useTranslation();
  return (
    <div className="container-fluid pt-5 p-0 value-person-view">
      <Row className="mb-4 px-4">
        <Col xs={6}>
          <BoxedMetric
            titleFn={() => t('Sponsor Value')}
            metric='media-value'
            primaryFn={(primary) => `${numeral(primary).format('0.0a')} NOK`}
            secondaryFn={(secondary) => `${t('Growth Rate')} ${numeral(secondary).format('0%')}`}
            colour="red"
          />
        </Col>
        <Col xs={6}>
          <BoxedMetric
            titleFn={() => t('Engagement Value')}
            metric='engagement-value'
            colour="yellow"
            primaryFn={(primary) => `${numeral(primary).format('0.0a')} NOK`}
            secondaryFn={(secondary) => `${t('Growth Rate')} ${numeral(secondary).format('0%')}`}
          />
        </Col>
      </Row>
      <Row className="px-4">
        <Col xs={12} className="mb-3">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">
                {t('Reach Value')}
              </h4>
              <LineGraph title={t('Reach Value')} useGraphQuery={useReachValuePeriod} defaultActiveTab={1} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="px-4">
        <Col xs={12} className="mb-3">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">
                {t('Engagement Value')}
              </h4>
              <LineGraph title={t('Engagement Value')} useGraphQuery={useEngagementValuePeriod} defaultActiveTab={1} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="px-4">
        <Col xs={12} className="mb-3">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">
                {t('Sponsor Value')}
              </h4>
              <LineGraph title={t('Sponsor Value')} useGraphQuery={useMediaValuePeriod} defaultActiveTab={1} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <AthleteSponsorValue />
    </div>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  selectedCurrency: state.currency.selectedCurrency,
	tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(ValuePerson);
