export const FILTER_CATEGORIES = {
  sponsors: {
    title: 'Sponsor Filters',
    type: 'sponsor',
    description: 'Compare demographics by sponsor recognition',
    availableWhen: () => true  // Always available
  },
  brands: {
    title: 'Brand Filters',
    type: 'brand',
    description: 'Compare brand recognition and associations',
    availableWhen: (baseAudience) => baseAudience === 'brand_recognizers'
  },
  athletes: {
    title: 'Athlete Filters',
    type: 'athlete',
    description: 'Compare demographics by athlete recognition'
  },
  interests: {
    title: 'Interest Filters',
    type: 'interest',
    description: 'Compare demographics by interest'
  }
};
