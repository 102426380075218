import React from 'react';
import { Row, Spinner, Badge } from 'reactstrap';
import usePartnerImageDetectionExample from '../query/hooks/usePartnerImageDetectionExample';

const ImageDetectionGrid = ({partnerId, sponsorObjectId}) => {
  const {data, isLoading, isRefetching} = usePartnerImageDetectionExample(partnerId, sponsorObjectId);
  return (
    <>
      {(isLoading || isRefetching) && <Spinner color="primary" />}
      <Row style={{ gap: 10, justifyContent: 'space-evenly' }}>
        {data?.map(img => (
          <div
            key={`img-${img.url}`}
            style={{
              position: 'relative',
              borderRadius: 20,
              overflow: 'hidden'
            }}
          >
            <img src={img.url} alt="" style={{ maxHeight: '200px' }} />
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: 80,
                background: 'linear-gradient(transparent, #00000094)'
              }}
            />
            <div style={{ position: 'absolute', bottom: 8, left: 8 }}>
              {img.brands.map(brand => (
                <Badge
                  key={`brand-${brand}`}
                  color="primary"
                  pill
                  className="ml-2"
                >
                  {brand}
                </Badge>
              ))}
            </div>
          </div>
        ))}
      </Row>
    </>
  );
};

export default ImageDetectionGrid;
