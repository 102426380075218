import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Table, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ApiService from '../services/ApiService';

const EventsPanel = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [broadcasts, setBroadcasts] = useState([]);
  const [loadingBroadcasts, setLoadingBroadcasts] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const response = await ApiService.getAdminEvents();
      setEvents(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
      setLoading(false);
    }
  };

  const fetchBroadcasts = async (eventId) => {
    try {
      setLoadingBroadcasts(true);
      const response = await ApiService.getEventBroadcasts(eventId);
      setBroadcasts(response.data);
      setLoadingBroadcasts(false);
    } catch (error) {
      console.error("Error fetching broadcasts:", error);
      setLoadingBroadcasts(false);
    }
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    fetchBroadcasts(event.public_id);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setBroadcasts([]);
    setSelectedEvent(null);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <>
      <div className="container-fluid">
        <Row className="mb-4">
          <Col xs={12}>
            <Card className="admin-card">
              <CardHeader className="admin-card-header">
                <h4 className="admin-card-title">Events</h4>
              </CardHeader>
              <CardBody>
                {loading ? (
                  <p>Loading events...</p>
                ) : (
                  <Table responsive hover className="table-centered mb-0">
                    <thead className="admin-table-header">
                      <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Tenant</th>
                        <th>Category</th>
                        <th>Class</th>
                        <th>City/Country</th>
                        <th>Time Period</th>
                        <th>TV Broadcasts</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {events.length === 0 ? (
                        <tr>
                          <td colSpan="9" className="text-center">No events found</td>
                        </tr>
                      ) : (
                        events.map((event) => (
                          <tr key={event.public_id}>
                            <td>{event.public_id}</td>
                            <td>{event.title}</td>
                            <td>{event.tenant ? event.tenant.name : 'N/A'}</td>
                            <td>{event.category}</td>
                            <td>{event.class}</td>
                            <td>{event.city}, {event.country}</td>
                            <td>
                              {formatDate(event.starts_at)} - {formatDate(event.ends_at)}
                            </td>
                            <td>{event.tv_broadcasts ? event.tv_broadcasts.length : 0}</td>
                            <td>
                              <Button 
                                color="primary" 
                                size="sm" 
                                onClick={() => handleEventClick(event)}
                                disabled={!event.tv_broadcasts || event.tv_broadcasts.length === 0}
                              >
                                View Broadcasts
                              </Button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal isOpen={modal} toggle={closeModal} size="lg">
        <ModalHeader toggle={closeModal}>
          {selectedEvent ? `TV Broadcasts for ${selectedEvent.title}` : 'TV Broadcasts'}
        </ModalHeader>
        <ModalBody>
          {loadingBroadcasts ? (
            <p>Loading broadcasts...</p>
          ) : broadcasts.length === 0 ? (
            <p>No broadcasts found for this event.</p>
          ) : (
            <Table responsive hover>
              <thead className="admin-table-header">
                <tr>
                  <th>Title</th>
                  <th>Channel</th>
                  <th>Source</th>
                  <th>Country</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Duration</th>
                  <th>Media</th>
                </tr>
              </thead>
              <tbody>
                {broadcasts.map((broadcast) => (
                  <tr key={broadcast.public_id}>
                    <td>{broadcast.title || 'N/A'}</td>
                    <td>{broadcast.channel || 'N/A'}</td>
                    <td>{broadcast.source || 'N/A'}</td>
                    <td>{broadcast.country || 'N/A'}</td>
                    <td>{formatDateTime(broadcast.starts_at)}</td>
                    <td>{formatDateTime(broadcast.ends_at)}</td>
                    <td>{broadcast.duration ? `${Math.floor(broadcast.duration / 60)} min` : 'N/A'}</td>
                    <td>
                      {broadcast.media ? (
                        <a href={broadcast.media.url} target="_blank" rel="noopener noreferrer">
                          View Media
                        </a>
                      ) : 'No media'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EventsPanel; 