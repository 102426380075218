import React from "react"
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official'
import { Spinner } from 'reactstrap'
import { connect } from "react-redux"
import { useThemeContext } from '../contexts/ThemeContext';

const BarGraph = ({
  useGraphQuery,
  queryParams,
  defaultActiveTab = 1,
  scrollbar = false,
  direction = 'vertical',
}) => {
  const { data, isLoading, isRefetching, isSuccess } = useGraphQuery(
    ...queryParams
  );
  const { isDark } = useThemeContext();
  const textColor = 'var(--text-primary)';
  const backgroundColor = 'transparent';

  const isHorizontal = direction === 'horizontal';
  const chartType = isHorizontal ? 'bar' : 'column';

  // For horizontal bars, we swap the axis titles
  const graph = data?.data?.graphs?.[0] || {};
  const actualXAxisTitle = isHorizontal ? graph?.data?.yTitle : graph?.data?.xTitle;
  const actualYAxisTitle = isHorizontal ? graph?.data?.xTitle : graph?.data?.yTitle;

  const chartOptions = (graph) => {
    // Calculate height based on number of data points
    const dataPoints = graph?.data?.labels?.length || 0;
    const minHeight = 400; // Increased minimum height to match other charts
    const heightPerItem = 30; // Height per data point
    const dynamicHeight = Math.max(minHeight, dataPoints * heightPerItem);

    return {
      credits: { enabled: false },
      chart: {
        type: chartType,
        backgroundColor: backgroundColor,
        marginLeft: isHorizontal ? 150 : 50,
        height: isHorizontal ? dynamicHeight : minHeight, // Set minimum height for all charts
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: actualYAxisTitle,
          style: {
            color: textColor
          }
        },
        labels: {
          style: {
            color: textColor
          },
          useHTML: isHorizontal,
          x: isHorizontal ? -10 : 0
        },
        scrollbar: {
          enabled: scrollbar,
        },
      },
      xAxis: {
        categories: graph?.data?.labels,
        labels: {
          style: {
            color: textColor
         }
        },
        title: {
          text: actualXAxisTitle,
          style: {
            color: textColor
          }
        },
      },
      series: graph?.data?.series || [],
      plotOptions: {
        series: {
          stacking: direction === 'horizontal' ? null : 'normal',
        },
        column: {
          borderColor: null
        },
        bar: {
          borderColor: null
        },
      },
    };
  };


  return (
    <>
      {(isLoading || isRefetching) && (
        <Spinner
          color="primary"
          size="sm"
          style={{ position: "absolute", right: 20 }}
        />
      )}
      {isSuccess && (
        <>
          {
            data && (
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions(graph)}
              />
            )
          }
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(BarGraph);
