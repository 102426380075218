import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useThemeContext } from '../../../contexts/ThemeContext';

const MediaUsageChart = ({ data = [], compareMode, loading, filterName, baseAudienceName }) => {
  const { theme } = useThemeContext();

  const chartOptions = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) {
      return null;
    }

    const textColor = getComputedStyle(document.documentElement).getPropertyValue('--text-primary').trim();
    const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--text-secondary').trim();

    const brandSeries = {
      name: baseAudienceName || 'Base Audience',
      data: data.map(item => ({
        name: item.channel_type,
        y: item.all_percentage !== null ? parseFloat(item.all_percentage) : null
      }))
    };

    const comparisonSeries = compareMode ? {
      name: filterName || 'Comparison Audience',
      data: data.map(item => ({
        name: item.channel_type,
        y: item.comparison_percentage !== null ? parseFloat(item.comparison_percentage) : null
      }))
    } : null;

    return {
      chart: {
        type: 'bar',
        backgroundColor: 'transparent'
      },
      title: {
        text: null
      },
      xAxis: {
        categories: data.map(item => item.channel_type),
        title: {
          text: 'Media Type',
          style: {
            color: "var(--text-primary)"
          }
        },
        labels: {
          style: {
            color: "var(--text-primary)"
          }
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: 'Percentage (%)',
          style: {
            color: "var(--text-primary)"
          }
        },
        labels: {
          format: '{value}%',
          style: {
            color: "var(--text-primary)"
          }
        }
      },
      tooltip: {
        shared: true,
        pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:.1f}%</b><br/>'
      },
      legend: {
        itemStyle: {
          color: "var(--text-primary)"
        },
        itemHoverStyle: {
          color: "var(--text-secondary)"
        }
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            format: '{y}%',
            style: {
              color: "var(--text-primary)"
            }
          }
        }
      },
      series: compareMode ? 
        [brandSeries, comparisonSeries].filter(Boolean) : 
        [brandSeries],
      colors: ['#FFB81C', '#4A90E2'],
      credits: {
        enabled: false
      }
    };
  }, [data, compareMode, filterName, baseAudienceName, theme]);

  if (loading) {
    return <div>Loading media usage data...</div>;
  }

  if (!chartOptions) {
    return <div>No media usage data available</div>;
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};

export default MediaUsageChart;
