import React, { useMemo, useState } from "react"
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Spinner, UncontrolledDropdown } from 'reactstrap'
import classnames from 'classnames'
import { connect } from "react-redux"
import { ChevronDownIcon } from "@radix-ui/themes/dist/cjs/index.js";
import { useTranslation } from "react-i18next";

const LineGraph = ({
  useGraphQuery,
  queryParams = [],
  defaultActiveTab = 1,
  multipliers = [],
  noTabs = false,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const { data, isLoading, isRefetching, isSuccess } = useGraphQuery(
    ...queryParams
  );
  const [multiplier, setMultiplier] = useState(1);

  const activeTabData = useMemo(() => JSON.parse(JSON.stringify(data?.[activeTab] ?? [])), [data, activeTab]);

  const chartOptions = (graph) => {
    if (!graph.data) {
      return;
    }
    const series = graph.data.series.map((s) => ({
      ...s,
      data: s.data.map((d) => d * multiplier),
    }));
    return {
      credits: { enabled: false },
      chart: {
        type: "spline",
        backgroundColor: 'transparent',  // <-- Add background color
      },
      title: {
        text: "",
      },
      yAxis: {
        title: {
          text: graph.data.yTitle,
          style: {
            color: 'var(--text-primary)'
          }
        },
        labels: {
          style: {
            color: 'var(--text-primary)'
         }
        }
      },
      xAxis: {
        categories: graph.data.labels,
        labels: {
          //rotation: 0,
          step: 0,
          style: {
            color: 'var(--text-primary)'
         }
        },
        title: {
          text: graph.data.xTitle,
          style: {
            color: 'var(--text-primary)'
          }
        },
      },
      series,
    };
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTab = (graph, index) => {
    return (
      <NavItem key={index}>
        <NavLink
          href="#"
          className={classnames({ active: activeTab === index })}
          onClick={() => toggleTab(index)}
        >
          {graph.source}
        </NavLink>
      </NavItem>
    );
  };

  return (
    <>
      {(isLoading || isRefetching) && (
        <Spinner
          color="primary"
          size="sm"
          style={{ position: "absolute", right: 20 }}
        />
      )}
      {isSuccess && (
        <>
          <div style={{display: 'flex', alignItems: 'center'}} className={(noTabs === false || multiplier.length) > 0 ? "my-4 flex wrap flex-wrap" : "flex wrap flex-wrap"}>
            <div style={{flex: 1}}>
              {multipliers.length > 0 && (
                <div className="md-flex" style={{alignItems: 'center', flexDirection: 'column'}}>
                  <span>{t('CPM discount')}</span>
                  <UncontrolledDropdown style={{flex: 1, padding: 0}}>
                    <DropdownToggle className="btn " tag="button" style={{padding: 0}}>
                      <Button size="sm" color="light" outline>
                        {multiplier * 100}%
                        <ChevronDownIcon style={{marginLeft: '8px'}} />
                      </Button>
                    </DropdownToggle>
                    <DropdownMenu>
                      {multipliers.map(multiplier => (
                        <DropdownItem key={`multiplier-${multiplier}`} onClick={() => setMultiplier(multiplier)}>{multiplier * 100}%</DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </div>
            {
              noTabs === false && (
                <Nav pills className="navtab-bg center-pills">
                  {Array.isArray(data) && data.map((graph, index) => renderTab(graph, index))}
                </Nav>
              )
            }
            <div style={{flex: 1}}></div>
            </div>
            { noTabs === true && (
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions(data)}
              />
            )

            }
            {
              noTabs === false && activeTabData && data?.[activeTab] && (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions(activeTabData)}
                />
              )
            }
        </>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  date: state.date,
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(LineGraph);
