// import i18next from 'i18next';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import ApiService from '../services/ApiService';



import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from "reactstrap";

// flags
import noFlag from '../assets/images/flags/norwegian_flag.png'
import enFlag from '../assets/images/flags/uk_flag.png'
import { useQuery, useQueryClient } from '@tanstack/react-query';


const LanguageSelect = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [menu, setMenu] = useState(false);
  const { i18n } = useTranslation();
  const queryClient = useQueryClient();

  const { data: language } = useQuery({
    queryKey: ['language'],
    queryFn:  ApiService.getLang,
    select: (data) => data.data,
    placeholderData: {data: ''},
    cacheTime: 0,
    staleTime: 0,
  });

  useEffect(() => {
    if (!language) {
      return;
    }
    i18n.changeLanguage(language);
  }, [i18n, language]);


  function toggle() {
    setMenu(
      !menu
    );
  }

  function handleChange(changedLanguage)
  {
    i18n.changeLanguage(changedLanguage);
    changeLanguageBackend(changedLanguage)
  }

  const changeLanguageBackend = async (changedLanguage) => {
    // call backend to change language, then reload page to load graphs with new language
    setIsLoading(true);
    try {
      await ApiService.changeLang(changedLanguage);
      await queryClient.invalidateQueries([]);
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  }

  const codeToLanguage = (code) => {
    switch (code) {
      case 'no':
        return "Norsk";
      case 'en':
        return "English";
      default:
        return "Norsk";
    }
  }

  const getFlag = (language) => {
    switch (language) {
      case 'no':
        return noFlag;
      case 'en':
        return enFlag;
      default:
        return noFlag;
    }
  }

  const toggleButton = (languageCode) => {
    if (languageCode !== language){
      return (
        <DropdownItem tag="a" href="#" className="notify-item" onClick={() => handleChange(languageCode)}>
            <img
              src={getFlag(languageCode)}
              alt="veltrix"
              className="mr-1"
              height="12"
            />{" "}
            <span className="align-middle"> {codeToLanguage(languageCode) } </span>
          </DropdownItem>
        )
      }
    }
  
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-none d-md-block ml-2"
      >
        <DropdownToggle
          className="btn header-item waves-effecte"
          tag="button"
        >
          <img
            className="mr"
            src={getFlag(language)}
            alt="Header Language"
            height="16"
          />{" "}
          {codeToLanguage(language)}
          <span className="mdi mdi-chevron-down"></span>
          {
            isLoading && (
              <Spinner className="ml-2" size="sm" color="white" />
            )
          }
        </DropdownToggle>
        <DropdownMenu className="language-switch" right>
          {toggleButton('en')}
          {toggleButton('no')}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}
  

export default LanguageSelect