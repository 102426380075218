import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useThemeContext } from '../../../contexts/ThemeContext';

const AGE_GROUP_LABELS = {
  '1': '18-24',
  '2': '25-34',
  '3': '35-44',
  '4': '45-54',
  '5': '55-64',
  '6': '65+'
};

const GENDER_LABELS = {
  'Mann': 'Mann',
  'Kvinne': 'Kvinne'
};

const DemographicChart = ({ data = [], compareMode, loading, filterName, baseAudienceName }) => {
  const { theme } = useThemeContext();

  const chartOptions = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) {
      return null;
    }

    // Group data by age group
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.age_group]) {
        acc[item.age_group] = [];
      }
      acc[item.age_group].push(item);
      return acc;
    }, {});

    // Create series for each gender
    const maleSeries = {
      name: `${baseAudienceName || 'Base'} - Mann`,
      stack: 'base',
      color: '#FFB020', // Yellow for base Mann
      data: Object.entries(groupedData).map(([ageGroup, items]) => {
        const maleItem = items.find(item => item.gender === 'Mann');
        return maleItem ? parseFloat(maleItem.percentage) : 0;
      })
    };

    const femaleSeries = {
      name: `${baseAudienceName || 'Base'} - Kvinne`,
      stack: 'base',
      color: '#F76707', // Orange for base Kvinne
      data: Object.entries(groupedData).map(([ageGroup, items]) => {
        const femaleItem = items.find(item => item.gender === 'Kvinne');
        return femaleItem ? parseFloat(femaleItem.percentage) : 0;
      })
    };

    // If comparison mode is enabled, add comparison series
    const maleComparisonSeries = compareMode ? {
      name: `${filterName || 'Comparison'} - Mann`,
      stack: 'comparison',
      color: '#2F7EC7', // Blue for comparison Mann
      data: Object.entries(groupedData).map(([ageGroup, items]) => {
        const maleItem = items.find(item => item.gender === 'Mann');
        return maleItem?.comparison?.percentage ?? 0;
      })
    } : null;

    const femaleComparisonSeries = compareMode ? {
      name: `${filterName || 'Comparison'} - Kvinne`,
      stack: 'comparison',
      color: '#14B8A6', // Teal for comparison Kvinne
      data: Object.entries(groupedData).map(([ageGroup, items]) => {
        const femaleItem = items.find(item => item.gender === 'Kvinne');
        return femaleItem?.comparison?.percentage ?? 0;
      })
    } : null;

    const series = [maleSeries, femaleSeries];
    if (compareMode) {
      series.push(maleComparisonSeries, femaleComparisonSeries);
    }

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          color: "var(--text-primary)" // Use CSS variable directly
        }
      },
      title: {
        text: null
      },
      xAxis: {
        categories: Object.keys(groupedData).map(group => AGE_GROUP_LABELS[group] || group),
        title: {
          text: 'Age Groups',
          style: {
            color: "var(--text-primary)"
          }
        },
        labels: {
          style: {
            color: "var(--text-primary)"
          }
        },
        lineColor: "var(--text-primary)",
        tickColor: "var(--text-primary)"
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: 'Percentage (%)',
          style: {
            color: "var(--text-primary)"
          }
        },
        labels: {
          style: {
            color: "var(--text-primary)"
          }
        },
        gridLineColor: "var(--text-secondary)",
        lineColor: "var(--text-primary)",
        tickColor: "var(--text-primary)",
        tickInterval: 20
      },
      legend: {
        itemStyle: {
          color: "var(--text-primary)"
        },
        itemHoverStyle: {
          color: "var(--text-primary)"
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          grouping: true,
          shadow: false,
          borderWidth: 0,
          groupPadding: 0.15
        }
      },
      tooltip: {
        shared: true,
        backgroundColor: theme === 'dark' ? '#1F2937' : '#FFFFFF',
        style: {
          color: "var(--text-primary)"
        },
        formatter: function() {
          let tooltip = `<b>${this.x}</b><br/>`;
          this.points.forEach(point => {
            tooltip += `${point.series.name}: <b>${point.y.toFixed(1)}%</b><br/>`;
          });
          return tooltip;
        }
      },
      series: series.filter(Boolean),
      credits: {
        enabled: false
      }
    };
  }, [data, compareMode, filterName, baseAudienceName, theme]);

  if (loading) {
    return <div>Loading demographic data...</div>;
  }

  if (!chartOptions) {
    return <div>No demographic data available</div>;
  }

  return (
    <div className="chart-container">
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  );
};

export default DemographicChart;
