import DemographicChart from '../charts/DemographicChart';
import GeographyMap from '../charts/GeographyMap';
import RadialChart from '../charts/RadialChart';
import MediaUsageChart from '../charts/MediaUsageChart';
import BrandRecognitionChart from '../charts/BrandRecognitionChart';
import AthleteRecognition from '../charts/AthleteRecognition';

export const CHART_TYPES = {
  BASE_DEMOGRAPHICS: 'BASE_DEMOGRAPHICS',
  BRAND_SPECIFIC: 'BRAND_SPECIFIC',
  ATHLETE_SPECIFIC: 'ATHLETE_SPECIFIC',
  COMPARISON: 'COMPARISON',
  BRAND_RECOGNITION: 'BRAND_RECOGNITION',
  ATHLETE_RECOGNITION: 'ATHLETE_RECOGNITION'
};

export const CHART_CONFIGS = {
  demographics: {
    type: CHART_TYPES.BASE_DEMOGRAPHICS,
    component: DemographicChart,
    title: 'Demographics',
    showConditions: () => true,
    gridWidth: 6 // out of 12 columns
  },
  mediaUsage: {
    type: CHART_TYPES.COMPARISON,
    component: MediaUsageChart,
    title: 'Digital Platforms',
    showConditions: () => true,
    gridWidth: 6
  },
  geography: {
    type: CHART_TYPES.BASE_DEMOGRAPHICS,
    component: GeographyMap,
    title: 'Geography',
    showConditions: () => true,
    gridWidth: 12
  },
  brandAssociations: {
    type: CHART_TYPES.BRAND_SPECIFIC,
    component: RadialChart,
    title: 'Brand Associations',
    showConditions: (baseAudience, filters = []) => {
      // Never show when base audience is 'everyone'
      if (baseAudience === 'everyone') {
        return false;
      }
      // Show if:
      // 1. Base audience is a specific brand
      // OR
      // 2. We have a sponsor filter selected
      return true;
    },
    gridWidth: 6
  },
  brandRecognition: {
    type: CHART_TYPES.BRAND_SPECIFIC,
    component: BrandRecognitionChart,
    title: 'Brand Recognition',
    showConditions: (baseAudience, filters = []) => {
      // Show if:
      // 1. Base audience is a specific brand (not 'everyone')
      // OR
      // 2. We have a sponsor filter selected
      return baseAudience !== 'everyone' || filters.some(f => f.type === 'sponsor');
    },
    gridWidth: 6
  },
  athleteRecognition: {
    type: CHART_TYPES.ATHLETE_RECOGNITION,
    component: AthleteRecognition,
    title: 'Athlete Recognition',
    description: 'Shows how different age groups recognize the selected athlete',
    endpoint: '/api/audience-insights/athlete-recognition',
    params: (filters = []) => {
      // Only get the athlete filter
      const athleteFilter = filters.find(f => f.type === 'athlete');
      return {
        athlete: athleteFilter?.value
      };
    },
    showConditions: (baseAudience, filters = []) => {
      // Only show when exactly one athlete filter is selected
      const athleteFilters = filters.filter(f => f.type === 'athlete');
      return athleteFilters.length === 1;
    },
    gridWidth: 12,
  },
};
