import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { PROJECTS, PROJECT_OVERVIEW } from "../keys";
import { format } from "date-fns";
import numeral from "numeral";

const useProjectOverview = (projectId) => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }

  return useQuery({
    queryKey: [PROJECTS, PROJECT_OVERVIEW, tenant.id, projectId, startDate, endDate],
    queryFn: () => ApiService.getProjectOverview(since, until, projectId),
    select: (data) => {
      const rows = data.data.rows.map((row) => {
        return {
          ...row,
          impression: numeral(row.impression).format("0,0"),
          impressionValue: numeral(row.impressionValue).format("0,0"),
          engagement: numeral(row.engagement).format("0,0"),
          engagementValue: numeral(row.engagementValue).format("0,0"),
        };
      });
      return { ...data.data, rows };
    },
    placeholderData: { data: { headers: [], rows: [] } },
    enabled,
  });
};

export default useProjectOverview;
