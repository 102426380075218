import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { PROJECTS, PROJECT_ENGAGEMENT } from "../keys";
import { format } from "date-fns";

const useProjectEngagement = (params) => {
  const projectId = params[0];
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }

  return useQuery({
    queryKey: [PROJECTS, PROJECT_ENGAGEMENT, tenant.id, projectId, startDate, endDate],
    queryFn: () => ApiService.getProjectEngagement(since, until, projectId),
    select: (data) => data.data.graphs,
    placeholderData: { data: { graphs: [] } },
    enabled,
  });
};

export default useProjectEngagement;
