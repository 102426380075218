import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import numeral from "numeral";
import { Col, Row, Spinner } from "reactstrap";

const SummaryDonutChart = ({useQuery, queryParams = null}) => {
  const { data, isFetching } = useQuery(queryParams);

  const chartOptions = () => {
    return {
      credits: { enabled: false },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false
            },
        }
    },
      title: {
        text: numeral(data?.total).format('0.0a') ?? 0,
        verticalAlign: 'middle',
        y: 25,
        style: {
          fontSize: '32px',
          color: 'white',
        }
      },
      subtitle: {
        text: `${data?.trend >= 0 ? '+' : ''}${data?.trend}%`,
        verticalAlign: 'middle',
        y: 45,
        style: {
          fontSize: '16px',
          color: 'var(--text-secondary)',
        }
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      xAxis: {
      },
      series: [{
        name: 'Verdi',
        innerSize: '75%',
        borderColor: 'transparent',
        data: data?.series ?? [],
    }]
    };
  }
  return (
    <>
      {isFetching ? (
        <Spinner color="primary" />
      ) : null}
      <Row style={{flexWrap: 'wrap-reverse'}} className="px-4">
        <Col xs={12} sm={12} md={12} lg={8} xl={6}>
          <div className="flex flex-column justify-content-center h-100">
            <table>
              <tbody>
                {data?.series?.map?.((item, index) => (
                  <tr key={index}>
                    <td className="flex align-items-center mt-2 mb-2 mr-4">
                        <div
                          style={{backgroundColor: item.color, width: 42, height:  32, borderRadius: 5}}
                          className="mr-3"
                        />

                        <p className="m-0">
                          {item.name}
                        </p>
                    </td>
                    <td className="mr-4">
                      {numeral(item.y).format('0.0a')}
                    </td>
                    <td className={item.trend >= 0 ? 'positive-number ml-4' : 'negative-number ml-4'}>
                      {item.trend >= 0 ? '+' : ''}{item.trend}%
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} xl={6}>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions()}
          />
        </Col>
      </Row>
      </>
  )
};

export default SummaryDonutChart;
