import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { SPONSOR_TV_DIVDED } from "../keys";
import { format, parse } from "date-fns";

const useSponsorTvDiveded = (sponsorObjectId) => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }

  return useQuery({
    queryKey: [SPONSOR_TV_DIVDED, tenant.id, sponsorObjectId, since, until],
    queryFn: () => ApiService.getSponsorTvDivided(since, until, sponsorObjectId),
    select: (data) => {
      const videos = data?.data?.videos || [];
      
      // Process video names to separate event name from date
      const processedVideos = videos.map(video => {
        // Extract the date part from pretty_name
        const dateMatch = video.pretty_name.match(/\((\d{2}-\d{2}-\d{4})\)$/);
        let eventName = video.pretty_name;
        let dateStr = '';
        
        if (dateMatch && dateMatch[1]) {
          // Remove the date part from the event name
          eventName = video.pretty_name.replace(` (${dateMatch[1]})`, '');
          
          // Format the date to be shorter (DD/MM)
          try {
            const dateObj = parse(dateMatch[1], 'dd-MM-yyyy', new Date());
            dateStr = format(dateObj, 'dd/MM');
          } catch (e) {
            dateStr = dateMatch[1];
          }
        }
        
        return {
          ...video,
          eventName,
          shortDate: dateStr
        };
      });
      
      // Create graph data in the expected format
      const formattedData = {
        data: {
          graphs: [
            {
              data: {
                // Use formatted labels with event name and date on separate lines
                labels: processedVideos.map(video => `${video.eventName}<br><span style="opacity: 0.7; font-size: 0.85em;">${video.shortDate}</span>`),
                series: [
                  {
                    name: "Watched whole match",
                    data: processedVideos.map(video => video.watched_whole_match),
                    color: "var(--primary)"
                  },
                  {
                    name: "Watched more than one minute",
                    data: processedVideos.map(video => video.watched_more_than_one_min),
                    color: "var(--warning)"
                  }
                ],
                xTitle: "Number of viewers",
                yTitle: "Matches"
              }
            }
          ]
        }
      };
      
      return formattedData;
    },
    placeholderData: { data: { graphs: [] } },
  });
};
export default useSponsorTvDiveded;
