import React from 'react'
import { Col, Row, Card, CardBody, CardHeader } from 'reactstrap';
import AdminJobsPanel from '../components/AdminJobsPanel'
import AdminMeltwater from '../components/AdminMeltwater';
import AdminS3Upload from '../components/AdminS3Upload';
import AdminUserManagement from '../components/AdminUserManagement';
import AdminTVUpload from '../components/AdminTVUpload';
import AdminFileUpload from '../components/AdminFileUpload';
import ApiService from '../services/ApiService';

const AdminPanel = () => {
  return (
    <div className="container-fluid">
      <Row className="mb-4">
        <Col xs={12}>
          <Card className="admin-card">
            <CardHeader className="admin-card-header">
              <h4 className="admin-card-title">Admin Dashboard</h4>
            </CardHeader>
            <CardBody>
              <AdminUserManagement />
              <AdminMeltwater />
              <AdminJobsPanel />
              <AdminS3Upload />
              <AdminTVUpload />
              <Row>
                <Col xs={6}>
                  <AdminFileUpload type="Medietall" accept=".xlsx" apiRequest={ApiService.importMedietall} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default AdminPanel