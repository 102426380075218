import React from "react";
import { Collapse } from "reactstrap";
import { NavLink } from "react-router-dom";
import DatePicker from "../components/DatePicker";
import { connect } from "react-redux";
import { setDate } from "../store/actions/dateActions";
import { useTranslation } from "react-i18next";
import PartnerLink from "../components/PartnerLink";

const NavbarLeague = ({ menuOpen, date, setDate, tenant, tenants }) => {
  const { startDate, endDate } = date;
  const { t } = useTranslation();

  const onChange = (date) => {
    const [start, end] = date;
    setDate({
      startDate: new Date(
        new Date(start).getFullYear(),
        new Date(start).getMonth(),
        1
      ),
      endDate: end
        ? new Date(new Date(end).getFullYear(), new Date(end).getMonth() + 1, 0)
        : undefined,
    });
  };

  const klubb = () => {
    if (["NFF", "Døds", "Team Aker Dæhlie"].includes(tenant.name)) {
      return null;
    }
    const children = tenants.filter(
      (currentTenant) => currentTenant.parent === tenant.id
    );
    if (children.length > 0) {
      return children.map((child) => (
        <li className="nav-item" key={child.id}> {/* Added unique key prop here */}
          <NavLink className="nav-link" to={`/club/${child.id}`}>
            <i className="ti-user mr-2"></i>
            {child.name}
          </NavLink>
        </li>
      ));
    } else {
      return (
        <li className="nav-item">
          <NavLink className="nav-link" to="/club">
            <i className="ti-user mr-2"></i>
            {t("Klubb")}
          </NavLink>
        </li>
      );
    }
  };

  return (
    <>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
            <Collapse isOpen={menuOpen} className="navbar-collapse">
              <div id="navigation">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/dashboard">
                      <i className="ti-pulse mr-2"></i>
                      {t("Oversikt")}
                    </NavLink>
                  </li>
                  {klubb()}
                  <PartnerLink />
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/brand">
                      <i className="ti-heart mr-2"></i>
                      {t("Merkevare")}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/reports">
                      <i className="ti-clipboard mr-2"></i>
                      {t("Rapport")}
                    </NavLink>
                  </li>
                </ul>
              </div>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                showMonthYearPicker
                dateFormat="MMM yy"
                confirm
                selectsRange
              />
            </Collapse>
          </nav>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  date: state.date,
  tenants: state.auth.tenants,
});

export default connect(mapStateToProps, { setDate })(NavbarLeague);
