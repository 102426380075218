import React, { useState } from 'react'
import { Nav, NavItem, NavLink, Spinner } from 'reactstrap'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import usePartners from '../query/hooks/usePartners'

const PartnerTabs = ({onClickTab}) => {
  const [activeTab, setActiveTab] = useState(0)
  const {data: partners, isFetching} = usePartners();

  const { t } = useTranslation()

  function toggleTab(tabIndex, partnerId) {
    if (activeTab !== tabIndex) {
      setActiveTab(tabIndex)
      onClickTab(tabIndex, partnerId)
    }
  }

  function renderTab(tab, index) {
    return (
      <NavItem key={index+1}>
        <NavLink
          href="#"
          className={classnames({
            active: activeTab === index+1
          })}
          onClick={() => {
            toggleTab(index+1, tab.id)
          }}
        >
          {tab.name}
        </NavLink>
      </NavItem>
    );
  };


  return (
    <div className="flex align-items-center">
      {isFetching && (
        <div className="mr-4">
          <Spinner />
        </div>
      )}
      {
        partners.length > 0 && (
          <Nav tabs className="nav-tabs-custom mb-4 mt-4 flex-fill">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === 0
                })}
                onClick={() => {
                  toggleTab(0, 0)
                }}
              >
                <span className="d-none d-sm-block">{t('Portfolio')}</span>
              </NavLink>
            </NavItem>
            {partners.map((tab, index) => renderTab(tab, index))}
          </Nav>
        )
      }
    </div>
  )
}


export default PartnerTabs;
