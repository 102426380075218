import React, { useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spinner } from "reactstrap";
import { useThemeContext } from "../contexts/ThemeContext";
import { useLogoDetectionStats } from "../query/hooks/useLogoDetectionStats";

const LogoDetectionStatsChart = ({ sponsorObjectId }) => {
  const { isDarkMode } = useThemeContext();

  const {
    data,
    isLoading,
    isRefetching,
    isSuccess,
  } = useLogoDetectionStats(sponsorObjectId);

  const graph = useMemo(() => {
    if (!data?.data?.data) return null;

    // Group by day, sum up minutes
    const byDay = {};
    data.data.data.forEach((item) => {
      if (!byDay[item.day]) {
        byDay[item.day] = 0;
      }
      byDay[item.day] += parseFloat(item.sum_exposure_minutes);
    });

    const days = Object.keys(byDay).sort();
    
    // Format dates for x-axis
    const formattedData = days.map(day => [
      Date.parse(day),
      byDay[day]
    ]);

    // Create series for minutes
    const series = [
      {
        name: 'Exposure Time',
        data: formattedData,
        color: 'var(--primary)'
      }
    ];

    return {
      title: "Logo Detection Stats",
      data: {
        series
      },
    };
  }, [data]);

  const chartOptions = (graph) => {
    if (!graph) return {};

    return {
      credits: { enabled: false },
      title: {
        text: "",
      },
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
      },
      xAxis: {
        categories: graph.data.labels,
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%e %b'
        },
        labels: {
          style: {
            color: 'var(--text-primary)'
          }
        },
        title: {
          text: 'Date',
          style: {
            color: 'var(--text-primary)'
          }
        }
      },
      yAxis: {
        title: {
          text: "Minutes",
          style: {
            color: 'var(--text-primary)'
          }
        },
        labels: {
          style: {
            color: 'var(--text-primary)'
          }
        }
      },
      tooltip: {
        xDateFormat: '%e %b %Y',
        valueSuffix: ' minutes'
      },
      legend: {
        enabled: false
      },
      series: graph.data.series
    };
  };

  if (isLoading || isRefetching) {
    return (
      <div className="chart-container">
        <Spinner color="primary" />
      </div>
    );
  }

  if (!isSuccess || !graph) {
    return null;
  }

  return (
    <div className="chart-container">
      <HighchartsReact highcharts={Highcharts} options={chartOptions(graph)} />
    </div>
  );
};

export default LogoDetectionStatsChart;
