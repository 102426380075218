import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useThemeContext } from '../../../contexts/ThemeContext';

const AGE_GROUP_LABELS = {
  '1': '15-24 år',
  '2': '25-34 år',
  '3': '35-44 år',
  '4': '45-54 år',
  '5': '55-64 år',
  '6': '65+ år'
};

const RECOGNITION_LEVELS = {
  '1': { 
    label: 'Kjenner lite til', 
    color: '#3498db',  // Light blue
    description: 'Personer som kjenner lite til utøveren'
  },
  '2': { 
    label: 'Kjenner til, og følger med i media', 
    color: '#2ecc71',  // Green
    description: 'Personer som kjenner til og følger utøveren i media'
  },
  '3': { 
    label: 'Kjenner svært godt til', 
    color: '#f1c40f',  // Yellow
    description: 'Personer som kjenner svært godt til og følger utøveren i sosiale kanaler'
  },
  '4': { 
    label: 'Kjenner ikke til', 
    color: '#e74c3c',  // Red
    description: 'Personer som ikke kjenner til utøveren'
  }
};

const AthleteRecognition = ({ data, filterName, selectedFilters }) => {
  const { theme } = useThemeContext();

  // Get the athlete filter
  const athleteFilter = selectedFilters?.find(f => f.type === 'athlete');
  const athleteName = athleteFilter?.rowTitle || athleteFilter?.name || 'Utøver';

  if (!data || !athleteFilter) {
    return <div>No data available</div>;
  }
  
  const ageGroups = Object.keys(data);
  if (ageGroups.length === 0) {
    return <div>No recognition data available for {athleteName}</div>;
  }

  const chartOptions = {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'inherit'
      }
    },
    title: {
      text: `${athleteName} - kjennskap etter aldersgruppe`,
      style: {
        color: "var(--text-primary)"
      }
    },
    xAxis: {
      categories: ageGroups.map(group => AGE_GROUP_LABELS[group] || group),
      title: {
        text: 'Aldersgruppe',
        style: {
          color: "var(--text-primary)"
        }
      },
      labels: {
        style: {
          color: "var(--text-primary)"
        }
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: 'Prosent',
        style: {
          color: "var(--text-primary)"
        }
      },
      labels: {
        format: '{value}%',
        style: {
          color: "var(--text-primary)"
        }
      }
    },
    tooltip: {
      backgroundColor: theme === 'dark' ? '#2D3748' : '#FFFFFF',
      style: {
        color: theme === 'dark' ? '#FFFFFF' : '#1A202C'
      },
      formatter: function() {
        const level = RECOGNITION_LEVELS[this.series.userOptions.recognitionLevel];
        return `<b>${this.x}</b><br/>
                <span style="color: ${this.color}">\u25CF</span> ${level.label}: <b>${this.y.toFixed(1)}%</b><br/>
                <small>${level.description}</small>`;
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal'
      }
    },
    series: Object.keys(RECOGNITION_LEVELS).map(level => ({
      name: RECOGNITION_LEVELS[level].label,
      color: RECOGNITION_LEVELS[level].color,
      recognitionLevel: level,
      data: ageGroups.map(ageGroup => {
        const groupData = data[ageGroup];
        if (!groupData?.recognition_levels) return 0;
        const levelData = groupData.recognition_levels.find(r => r.level === parseInt(level));
        return levelData ? levelData.percentage : 0;
      })
    })),
    credits: {
      enabled: false
    }
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};

export default AthleteRecognition;
