import React from 'react';
import { useSponsorROI } from "../query/hooks";
import { Spinner } from "reactstrap";

const ROIWidget = ({ sponsorObjectId }) => {
  const { data: roiData, isFetching } = useSponsorROI(sponsorObjectId);

  const formatPercentage = (value, trend) => {
    const sign = trend >= 0 ? '+' : '-';
    return `${sign}${(value * 100).toFixed(1)}%`;
  };

  return (
      <div className="d-flex flex-column pt-3 flex-grow-1">
        {isFetching ? (
          <Spinner color="primary" />
        ) : null}
        <div className="d-flex justify-content-center align-items-end">
            <h3 className="mb-0 display-3">x{(roiData.roi).toFixed(2)}</h3>
            <div className={`ml-2 mb-2 ${roiData.trend >= 0 ? 'text-success' : 'text-danger'}`}>
              <span>{formatPercentage(Math.abs(roiData.trend), roiData.trend)}</span>
            </div>
        </div>

        <div className="mt-5 mx-auto" style={{width: '100%', maxWidth: '500px'}}>
          {roiData.series.map((item, index) => (
            <div key={index} className="d-flex align-items-center mb-3">
              <div style={{width: '60%'}}>
                <div className="font-weight-bold">{item.sponsor_object}</div>
                {/* <div className="text-muted">{item.brand}</div> */}
              </div>
              <div style={{width: '20%'}} className="text-right">
                x{item.roi.toFixed(2)}
              </div>
              <div style={{width: '20%'}} className="text-right">
                <span className={`${item.trend >= 0 ? 'text-success' : 'text-danger'}`}>
                  {formatPercentage(Math.abs(item.trend), item.trend)}
                </span>
              </div>
            </div>
          ))}
        </div>
    </div>
  );
};

export default ROIWidget;