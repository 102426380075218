import React, { useState } from 'react'
import { Row, Col, Card, CardBody, Spinner, Nav, NavItem, NavLink, Badge } from 'reactstrap'
import { useLocation } from "react-router-dom";
/* Components */

import ValuePerPartnerTable from '../components/ValuePerPartnerTable'
import DummyValueLogoExposes from '../components/DummyValueLogoExposes'
import StationDividedValueChart from '../components/StationDividedValueChart'
import SourceDividedValueChart from '../components/SourceDividedValueChart'

import OverallMetric from '../components/OverallMetric'
import { connect } from 'react-redux'
import { useTranslation, withTranslation } from 'react-i18next'
import LineGraph from "../components/LineGraph";
import {
  usePartnerDividedReach,
  usePartnerDividedValue,
  usePartnerReach,
  usePartnerReachValue,
  usePartnerEngagement,
  usePartnerEngagementValue,
  useMergedTenants,
} from "../query/hooks";
import classNames from 'classnames';
import ImageDetectionGrid from '../components/ImageDetectionGrid';

const PartnereLive = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const selectedPartnerId = new URLSearchParams(location.search).get('partner') ?? undefined;
  const {data: mergedTenants, isLoading} = useMergedTenants();
  const [selectedTenant, setSelectedTenant] = useState(undefined);

  if (isLoading) {
    return <Spinner color="primary" />;
  }

  return (
    <React.Fragment key={selectedPartnerId}>
      <div className="container-fluid pt-4">
        {mergedTenants.length > 1 && (
          <div className="mb-4">
            <Nav tabs className="nav-tabs-custom mb-4">
              <NavItem onClick={() => setSelectedTenant(undefined)}>
                  <NavLink
                      className={classNames({active: selectedTenant === undefined})}
                  >
                      <span className="d-none d-sm-block">Totalt</span>
                  </NavLink>
                </NavItem>
                {mergedTenants.map(tenant => (
                  <NavItem key={`tenant-${tenant.id}`} onClick={() => setSelectedTenant(tenant.id)}>
                    <NavLink
                        className={classNames({active: selectedTenant === tenant.id})}
                    >
                        <span className="d-none d-sm-block">{tenant.name}</span>
                    </NavLink>
                  </NavItem>
                ))
              }
            </Nav>
          </div>
        )}
        <Row className="mb-4">
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              numeralFormat="0.0a"
              metric="partner-value"
              isCurrency
              params={{partnerId: selectedPartnerId, selectedTenantId: selectedTenant}}
            />
          </Col>
          <Col xl={3} md={6} className="mb-4 mb-xl-0">
            <OverallMetric
              followCalender
              metric="partner-exposures"
              params={{partnerId: selectedPartnerId, selectedTenantId: selectedTenant}}
            />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric
              followCalender
              numeralFormat="0.0a"
              metric="partner-views"
              params={{partnerId: selectedPartnerId, selectedTenantId: selectedTenant}}
            />
          </Col>
          <Col xl={3} md={6}>
            <OverallMetric
              followCalender
              metric="partner-engagement"
              append="min"
              params={{partnerId: selectedPartnerId, selectedTenantId: selectedTenant}}
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Visninger per partner")}
                </h4>
                <LineGraph
                  useGraphQuery={usePartnerReach}
                  defaultActiveTab={0}
                  queryParams={[selectedPartnerId, selectedTenant]}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Synlighetsverdi per partner")} ({props.selectedCurrency.currency})
                </h4>
                <LineGraph
                  useGraphQuery={usePartnerReachValue}
                  defaultActiveTab={0}
                  queryParams={[selectedPartnerId, selectedTenant]}
                  multipliers={[1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Engasjement per partner")}
                </h4>
                <LineGraph
                  useGraphQuery={usePartnerEngagement}
                  defaultActiveTab={0}
                  queryParams={[selectedPartnerId, selectedTenant]}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Engasjementsverdi per partner")} (
                  {props.selectedCurrency.currency})
                </h4>
                <LineGraph
                  useGraphQuery={usePartnerEngagementValue}
                  defaultActiveTab={0}
                  queryParams={[selectedPartnerId, selectedTenant]}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Visninger per kanal")}
                </h4>
                <LineGraph
                  useGraphQuery={usePartnerDividedReach}
                  queryParams={[selectedPartnerId, selectedTenant]}
                  defaultActiveTab={0}
                />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Sponsorverdi per kanal")} (
                  {props.selectedCurrency.currency})
                </h4>
                <LineGraph
                  useGraphQuery={usePartnerDividedValue}
                  queryParams={[selectedPartnerId, selectedTenant]}
                  defaultActiveTab={0}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Sponsorverdi per partner")} (
                  {props.selectedCurrency.currency})
                </h4>
                <ValuePerPartnerTable partnerId={selectedPartnerId} selectedTenantId={selectedTenant} />
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Sponsorverdi per medie")} (
                  {props.selectedCurrency.currency})
                </h4>
                <SourceDividedValueChart partnerId={selectedPartnerId} selectedTenantId={selectedTenant} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100 has-overlay">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Sponsorverdi per plassering")} (
                  {props.selectedCurrency.currency})
                </h4>
                <DummyValueLogoExposes />
              </CardBody>
              <div className="overlay">
                <div className="overlay-content">
                  <p>
                    Plasseringsmålinger er ikke implementert i Foqus enda, men
                    er under utvikling. Ønsker du å måle verdi per plassering
                    og varelager, send en e-post til oss på{" "}
                    <a href="mailto:kim@foocus.ai">kim@foocus.ai</a> så kommer
                    vi tilbake til deg med et tilbudsforslag og mer
                    informasjon når dette er klart.
                  </p>
                </div>
              </div>
            </Card>
          </Col>
          <Col sm={12} md={6}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t("Sponsorverdi per TV-kanal")} (
                  {props.selectedCurrency.currency})
                </h4>
                <StationDividedValueChart partnerId={selectedPartnerId} selectedTenantId={selectedTenant} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col md={12}>
            <Card className="mini-stat bg-card text-white h-100">
              <CardBody>
                <h4 className="card-title mb-4">
                  {t('Ex. brand detections')}
                </h4>
                <ImageDetectionGrid partnerId={selectedPartnerId}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
    selectedCurrency: state.currency.selectedCurrency
});

export default withTranslation()(connect(mapStateToProps)(PartnereLive))