import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { BEST_POSTS } from "../keys";

const useBestPosts = (sorting, params) => {
  const tenant = useSelector((state) => state.auth.tenant);
  const date = useSelector((state) => state.date);

  const { startDate, endDate } = date;
  const enabled = !!(date.startDate && date.endDate);
  let since;
  let until;
  if (enabled) {
    since = format(date.startDate, "yyyy-MM-dd");
    until = format(date.endDate, "yyyy-MM-dd");
  }
  return useQuery({
    queryKey: [
      BEST_POSTS,
      tenant.id,
      `${sorting.order ? "-" : ""}${sorting.value}`,
      startDate,
      endDate,
      params,
    ],
    queryFn: () =>
      ApiService.getPerformingSocialMediaPosts(since, until, sorting.value, sorting.order ? 'asc' : 'desc', params),
    select: (res) => {
      const [facebook, instagram] = res.data;
      return facebook
        .concat(instagram)
        .sort((a, b) =>
          sorting.order
            ? a[sorting.value] - b[sorting.value]
            : b[sorting.value] - a[sorting.value]
        );
    },
    enabled,
    placeholderData: [{ data: [] }, { data: [] }],
    // Reduce stale time to 0 to always fetch fresh data
    staleTime: 0,
    // Cache data for 5 minutes
    cacheTime: 5 * 60 * 1000,
    // Refetch on window focus
    refetchOnWindowFocus: true,
    // Refetch on reconnect
    refetchOnReconnect: true,
    // Poll every 5 seconds to keep posts fresh
    refetchInterval: 5000,
    refetchIntervalInBackground: false
  });
};
export default useBestPosts;
