import React, { useState } from "react";
import ApiService from "../services/ApiService";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighMaps from "highcharts/modules/map";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Nav, NavItem, NavLink, Spinner } from "reactstrap";
import mapNorway from "@highcharts/map-collection/countries/no/no-all-all.geo.json";
import { useQuery } from "@tanstack/react-query";

HighMaps(Highcharts);

const Map = ({ tenant }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  const {
    data: sources,
    isLoading,
    isRefetching,
    isSuccess,
  } = useQuery({
    queryKey: ["followerGeography", tenant.id],
    queryFn: ApiService.getFollowerGeography,
    select: (data) => data.data.sources,
    placeholderData: { data: { sources: [] } }, // Make sure to match the correct data structure
  });

  const chartOptions = (source) => {
    const name = source.source.match(/[a-zA-Z]+/g);
    var array = source.data;
    var obj = Object.keys(array).map((key) => [key, array[key]]);

    const isPercent = source.source.includes("%");

    const maxValue = Math.max(...obj.map((o) => o[1]));
    return {
      title: {
        text: "",
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: "bottom",
        },
        enableDoubleClickZoomTo: true,
      },
      colorAxis: {
        maxColor: "#DDA600",
        minColor: "#F8EBC3",
        min: 0,
        max: maxValue,
        labels: {
          format: isPercent ? "{value}%" : "{value}",
          style: {
            color: "var(--text-primary)",
          },
        },
      },
      credits: { enabled: false },
      chart: {
        height: 403,
        backgroundColor: 'transparent',
      },

      series: [
        {
          tooltip: { valueSuffix: isPercent ? "%" : '' },
          type: "map",
          data: obj,
          nullColor: "#95969B",
          mapData: mapNorway,
          name: name,
          keys: ["name", "value"],
          joinBy: "name",
          borderWidth: 0.2,
          states: {
            hover: {
              color: "#BADA55",
            },
          },
          dataLabels: {
            enabled: true,
            color: 'var(--text-primary)',
            formatter: function () {
              if (this.point.value > 0) {
                return isPercent ? this.point.value + "%" : this.point.value;
              }
              return null;
            },
          },
        },
      ],
    };
  };

  const percentToggle = (activeTab) => {
    if (activeTab % 2 === 0) {
      return (
        <NavItem className="percent-button">
          <NavLink className="percent-link" active>
            %
          </NavLink>
          <NavLink
            className="percent-link"
            onClick={() => {
              toggleTab(prevActiveTab => prevActiveTab + 1);
            }}
          >
            {t("ant")}
          </NavLink>
        </NavItem>
      );
    } else {
      return (
        <NavItem className="percent-button">
          <NavLink
            className="percent-link"
            onClick={() => {
              toggleTab(prevActiveTab => prevActiveTab - 1);
            }}
          >
            %
          </NavLink>
          <NavLink className="percent-link" active>
            {t("ant")}
          </NavLink>
        </NavItem>
      );
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const renderTab = (activeTab) => {
    return (
      <>
        <NavItem key={activeTab}>
          <NavLink
            href="#"
            className={classnames({
              active: activeTab < 2,
            })}
            onClick={() => {
              toggleTab(0);
            }}
          >
            Facebook
          </NavLink>
        </NavItem>
        <NavItem key={activeTab + 1}>
          <NavLink
            href="#"
            className={classnames({
              active: activeTab > 1 && activeTab < 4,
            })}
            onClick={() => {
              toggleTab(2);
            }}
          >
            Instagram
          </NavLink>
        </NavItem>
      </>
    );
  };

  const activeSource = sources[activeTab];

  return (
    <>
      {(isLoading || isRefetching) && (
        <Spinner
          color="primary"
          size="sm"
          style={{ position: "absolute", right: 20 }}
        />
      )}
      {isSuccess && sources && sources.length > 0 && (
        <>
          <Nav pills className="navtab-bg my-4 center-pills">
            {renderTab(activeTab)}
          </Nav>

          <div className="p-3">
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions(activeSource)}
              constructorType={"mapChart"}
            />
            </div>
          <Nav pills className="navtab-bg center-pills">
            {percentToggle(activeTab)}
          </Nav>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  tenant: state.auth.tenant,
});

export default connect(mapStateToProps)(Map);