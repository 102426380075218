import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { useThemeContext } from '../../../contexts/ThemeContext';

// Initialize the HighchartsMore module
if (typeof Highcharts === 'object') {
  HighchartsMore(Highcharts);
}

const RadialChart = ({ data = [], compareMode, loading, filterName, baseAudienceName = 'Base Audience', selectedFilters }) => {
  const { theme } = useThemeContext();

  const chartOptions = useMemo(() => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return null;
    }

    // Sort data alphabetically by characteristic, handling undefined values
    const sortedData = [...data].sort((a, b) => {
      const aChar = a?.chars_characteristic || '';
      const bChar = b?.chars_characteristic || '';
      return aChar.localeCompare(bChar);
    });

    const categories = sortedData.map(item => item.chars_characteristic);

    // Initialize series array
    const series = [];
    
    // Check if we have an athlete filter
    const hasAthleteFilter = selectedFilters?.some(f => f.type === 'athlete');
    
    // Show base series if baseAudienceName is not 'Everyone'
    if (baseAudienceName !== 'Everyone') {
      series.push({
        name: baseAudienceName,
        data: categories.map(cat => {
          const item = sortedData.find(d => d.chars_characteristic === cat);
          return {
            name: cat,
            y: item?.percentage !== undefined && item?.percentage !== null 
              ? parseFloat(item.percentage) 
              : null
          };
        }),
        pointPlacement: 'on',
        color: theme === 'dark' ? '#FFD700' : '#FFB81C' // Gold color for base series
      });
    }

    // Add comparison series if in compare mode or if we have an athlete filter
    if (compareMode || hasAthleteFilter) {
      series.push({
        name: filterName || 'Comparison Audience',
        data: categories.map(cat => {
          const item = sortedData.find(d => d.chars_characteristic === cat);
          return {
            name: cat,
            y: item?.comparison_percentage !== undefined && item?.comparison_percentage !== null 
              ? parseFloat(item.comparison_percentage) 
              : null
          };
        }),
        pointPlacement: 'on',
        color: theme === 'dark' ? '#1E90FF' : '#0066CC' // Blue color for comparison series
      });
    }

    return {
      chart: {
        polar: true,
        type: 'line',
        backgroundColor: 'transparent'
      },
      colors: theme === 'dark' 
        ? ['#FFD700', '#1E90FF']  // Dark theme colors
        : ['#FFB81C', '#0066CC'], // Light theme colors
      title: {
        text: null
      },
      pane: {
        size: '80%'
      },
      xAxis: {
        categories,
        tickmarkPlacement: 'on',
        lineWidth: 0,
        labels: {
          style: {
            color: 'var(--text-primary)'
          }
        }
      },
      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0,
        max: 100,
        labels: {
          style: {
            color: 'var(--text-primary)'
          },
          format: '{value}%'
        }
      },
      tooltip: {
        shared: true,
        pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:.1f}%</b><br/>'
      },
      legend: {
        itemStyle: {
          color: 'var(--text-primary)'
        },
        itemHoverStyle: {
          color: 'var(--text-secondary)'
        }
      },
      plotOptions: {
        series: {
          animation: true,
          marker: {
            enabled: true
          }
        }
      },
      series: series,
      credits: {
        enabled: false
      }
    };
  }, [data, compareMode, theme, filterName, baseAudienceName, selectedFilters]);

  if (loading) {
    return <div>Loading brand association data...</div>;
  }

  if (!chartOptions) {
    return <div>No brand association data available</div>;
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
  );
};

export default RadialChart;
