import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader, Table, Row, Col, Badge, Button } from 'reactstrap';
import ApiService from '../services/ApiService';

const MediaPanel = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState('started_at');
  const [sortDirection, setSortDirection] = useState('desc');

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      setLoading(true);
      const response = await ApiService.getDetectionTasks();
      setTasks(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching detection tasks:", error);
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const getBroadcastInfo = (media) => {
    if (!media || !media.tv_broadcasts || media.tv_broadcasts.length === 0) {
      return 'No broadcast information';
    }
    
    const broadcast = media.tv_broadcasts[0];
    return (
      <div>
        <div><strong>Title:</strong> {broadcast.title || 'N/A'}</div>
        <div><strong>Channel:</strong> {broadcast.channel || 'N/A'}</div>
        <div><strong>Date:</strong> {formatDate(broadcast.starts_at)}</div>
      </div>
    );
  };

  const getVideoId = (media) => {
    if (!media || !media.tv_broadcasts || media.tv_broadcasts.length === 0) {
      return 'N/A';
    }
    return media.tv_broadcasts[0].public_id || 'N/A';
  };

  const getMediaId = (media) => {
    if (!media) return 'N/A';
    return media.public_id || 'N/A';
  };

  const getMediaInfo = (media) => {
    if (!media) return 'N/A';
    
    return (
      <div>
        <div><strong>Type:</strong> {media.type || 'N/A'}</div>
        <div><strong>Status:</strong> {media.status || 'N/A'}</div>
        <div>
          <strong>URL:</strong> 
          {media.url ? (
            <a href={media.url} target="_blank" rel="noopener noreferrer" className="ml-1">
              View Media
            </a>
          ) : 'N/A'}
        </div>
      </div>
    );
  };

  const handleSort = (field) => {
    if (sortField === field) {
      // If already sorting by this field, toggle direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Otherwise, sort by the new field in descending order
      setSortField(field);
      setSortDirection('desc');
    }
  };

  const getSortedTasks = () => {
    if (!tasks.length) return [];
    
    return [...tasks].sort((a, b) => {
      let aValue, bValue;
      
      // Determine values to compare based on sort field
      switch (sortField) {
        case 'public_id':
          aValue = a.public_id;
          bValue = b.public_id;
          break;
        case 'media_id':
          aValue = a.media ? a.media.public_id || '' : '';
          bValue = b.media ? b.media.public_id || '' : '';
          break;
        case 'video_id':
          aValue = a.media && a.media.tv_broadcasts && a.media.tv_broadcasts.length > 0 
            ? a.media.tv_broadcasts[0].public_id || '' 
            : '';
          bValue = b.media && b.media.tv_broadcasts && b.media.tv_broadcasts.length > 0 
            ? b.media.tv_broadcasts[0].public_id || '' 
            : '';
          break;
        case 'brand':
          aValue = a.brand ? a.brand.name : '';
          bValue = b.brand ? b.brand.name : '';
          break;
        case 'status':
          aValue = a.status;
          bValue = b.status;
          break;
        case 'provider':
          aValue = a.provider;
          bValue = b.provider;
          break;
        case 'media_type':
          aValue = a.media_type;
          bValue = b.media_type;
          break;
        case 'started_at':
          aValue = a.started_at ? new Date(a.started_at).getTime() : 0;
          bValue = b.started_at ? new Date(b.started_at).getTime() : 0;
          break;
        case 'ended_at':
          aValue = a.ended_at ? new Date(a.ended_at).getTime() : 0;
          bValue = b.ended_at ? new Date(b.ended_at).getTime() : 0;
          break;
        case 'updated_at':
          aValue = a.updated_at ? new Date(a.updated_at).getTime() : 0;
          bValue = b.updated_at ? new Date(b.updated_at).getTime() : 0;
          break;
        default:
          aValue = a[sortField];
          bValue = b[sortField];
      }
      
      // Compare the values
      if (aValue === bValue) return 0;
      
      if (sortDirection === 'asc') {
        return aValue < bValue ? -1 : 1;
      } else {
        return aValue > bValue ? -1 : 1;
      }
    });
  };

  const renderSortIcon = (field) => {
    if (sortField !== field) return null;
    
    return (
      <span className="ml-1">
        {sortDirection === 'asc' ? '▲' : '▼'}
      </span>
    );
  };

  return (
    <div className="container-fluid">
      <Row className="mb-4">
        <Col xs={12}>
          <Card className="admin-card">
            <CardHeader className="admin-card-header">
              <h4 className="admin-card-title">Media Detection Tasks</h4>
            </CardHeader>
            <CardBody>
              {loading ? (
                <p>Loading tasks...</p>
              ) : (
                <Table responsive hover className="table-centered mb-0">
                  <thead className="admin-table-header">
                    <tr>
                      <th style={{cursor: 'pointer'}} onClick={() => handleSort('public_id')}>
                        Task ID {renderSortIcon('public_id')}
                      </th>
                      <th style={{cursor: 'pointer'}} onClick={() => handleSort('media_id')}>
                        Media ID {renderSortIcon('media_id')}
                      </th>
                      <th style={{cursor: 'pointer'}} onClick={() => handleSort('video_id')}>
                        Video ID {renderSortIcon('video_id')}
                      </th>
                      <th style={{cursor: 'pointer'}} onClick={() => handleSort('brand')}>
                        Brand {renderSortIcon('brand')}
                      </th>
                      <th style={{cursor: 'pointer'}} onClick={() => handleSort('status')}>
                        Status {renderSortIcon('status')}
                      </th>
                      <th>Media Info</th>
                      {/* Only show broadcast info column if there are tasks with video type */}
                      {tasks.some(task => task.media_type === 'video') && (
                        <th>Broadcast Info</th>
                      )}
                      <th style={{cursor: 'pointer'}} onClick={() => handleSort('provider')}>
                        Provider {renderSortIcon('provider')}
                      </th>
                      <th style={{cursor: 'pointer'}} onClick={() => handleSort('media_type')}>
                        Media Type {renderSortIcon('media_type')}
                      </th>
                      <th style={{cursor: 'pointer'}} onClick={() => handleSort('started_at')}>
                        Started At {renderSortIcon('started_at')}
                      </th>
                      <th style={{cursor: 'pointer'}} onClick={() => handleSort('ended_at')}>
                        Ended At {renderSortIcon('ended_at')}
                      </th>
                      <th style={{cursor: 'pointer'}} onClick={() => handleSort('updated_at')}>
                        Last Updated {renderSortIcon('updated_at')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getSortedTasks().length === 0 ? (
                      <tr>
                        <td colSpan="12" className="text-center">No detection tasks found</td>
                      </tr>
                    ) : (
                      getSortedTasks().map((task) => (
                        <tr key={task.public_id}>
                          <td>{task.public_id}</td>
                          <td>{getMediaId(task.media)}</td>
                          <td>{getVideoId(task.media)}</td>
                          <td>{task.brand ? task.brand.name : 'N/A'}</td>
                          <td>
                            <Badge color={task.status === 'completed' ? 'success' : task.status === 'in_progress' ? 'info' : 'warning'} pill>
                              {task.status}
                            </Badge>
                          </td>
                          <td>{getMediaInfo(task.media)}</td>
                          {/* Only show broadcast info column if there are tasks with video type */}
                          {tasks.some(task => task.media_type === 'video') && (
                            <td>
                              {task.media_type === 'video' ? getBroadcastInfo(task.media) : 'N/A'}
                            </td>
                          )}
                          <td>{task.provider}</td>
                          <td>{task.media_type}</td>
                          <td>{formatDate(task.started_at)}</td>
                          <td>{formatDate(task.ended_at)}</td>
                          <td>{formatDate(task.updated_at)}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MediaPanel; 